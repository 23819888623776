import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "categoryProductSlider"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "sectionWrap" }
const _hoisted_4 = { class: "categoryProductSlider-header" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-sm-3" }
const _hoisted_7 = { class: "col-sm-9" }
const _hoisted_8 = { class: "categoryProductSlider-subs" }
const _hoisted_9 = {
  key: 0,
  class: "categoryProductSlider-subs-navigation"
}
const _hoisted_10 = ["textContent", "onClick"]
const _hoisted_11 = { class: "categoryProductSlider-contentr" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-md-3 sm-none" }
const _hoisted_14 = ["href"]
const _hoisted_15 = { class: "col-sm-12 col-md-9" }
const _hoisted_16 = { class: "categoryProductSlider-products" }
const _hoisted_17 = {
  key: 0,
  class: "categoryProductSlider-products-navigation"
}
const _hoisted_18 = { class: "product-cart-wrap loadMoreProducts" }
const _hoisted_19 = ["href", "textContent"]
const _hoisted_20 = { class: "product-cart-wrap loadingProduct" }
const _hoisted_21 = { class: "product-img-action-wrap" }
const _hoisted_22 = { class: "product-img product-img-zoom" }
const _hoisted_23 = { class: "product-content-wrap" }
const _hoisted_24 = { class: "product-rate-cover" }
const _hoisted_25 = {
  key: 1,
  class: "categoryProductSliderBelowBanners"
}
const _hoisted_26 = { class: "container" }
const _hoisted_27 = { class: "row" }
const _hoisted_28 = {
  key: 0,
  class: "col-sm-12 col-md-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_slide = _resolveComponent("ion-slide")!
  const _component_ion_slides = _resolveComponent("ion-slides")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ProductItem = _resolveComponent("ProductItem")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_Banner = _resolveComponent("Banner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.category['entity_id'])
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("h3", null, _toDisplayString(_ctx.category["name"]), 1)
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      (_ctx.showSubsArrows)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            (_ctx.showSubStartArrow)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  class: "ion-slides-prev",
                                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.subCatPrev()))
                                }, [
                                  _createVNode(_component_ion_icon, {
                                    icon: _ctx.icons.chevronBackOutline,
                                    color: "primary"
                                  }, null, 8, ["icon"])
                                ]))
                              : _createCommentVNode("", true),
                            (_ctx.showSubEndArrow)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 1,
                                  class: "ion-slides-next",
                                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.subCatNext()))
                                }, [
                                  _createVNode(_component_ion_icon, {
                                    icon: _ctx.icons.chevronForwardOutline,
                                    color: "primary"
                                  }, null, 8, ["icon"])
                                ]))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.category['childs'])
                        ? (_openBlock(), _createBlock(_component_ion_slides, {
                            key: 1,
                            pager: "true",
                            options: _ctx.slideSubCatOpts,
                            ref: "subCatSlider",
                            onIonSlideDidChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.ionSlideSubDidChange($event)))
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.category['childs'], (subCategory, index) => {
                                return (_openBlock(), _createBlock(_component_ion_slide, {
                                  key: index,
                                  class: _normalizeClass(["categoryProductSlider-sub", {
											active:
												subCategory.entity_id == _ctx.selectedSubCategory.entity_id,
										}])
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", {
                                      class: "categoryProductSlider-sub-inner",
                                      textContent: _toDisplayString(subCategory.name),
                                      onClick: ($event: any) => (_ctx.selectSubCategory(subCategory))
                                    }, null, 8, _hoisted_10)
                                  ]),
                                  _: 2
                                }, 1032, ["class"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["options"]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("a", {
                      class: "categoryProductSlider-category-imageWrap",
                      href: _ctx.getUrl(_ctx.category['url_path'])
                    }, [
                      _createVNode(_component_ion_img, {
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.getCategoryImageUrl(_ctx.category))),
                        src: _ctx.getCategoryImageUrl(_ctx.category)
                      }, null, 8, ["src"])
                    ], 8, _hoisted_14)
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      (_ctx.productsResults.length && !_ctx.loadingProducts)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                            (_ctx.showStartArrow)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  class: "ion-slides-prev",
                                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.productsPrev()))
                                }, [
                                  _createVNode(_component_ion_icon, {
                                    icon: _ctx.icons.chevronBackOutline,
                                    color: "primary"
                                  }, null, 8, ["icon"])
                                ]))
                              : _createCommentVNode("", true),
                            (_ctx.showEndArrow)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 1,
                                  class: "ion-slides-next",
                                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.productsNext()))
                                }, [
                                  _createVNode(_component_ion_icon, {
                                    icon: _ctx.icons.chevronForwardOutline,
                                    color: "primary"
                                  }, null, 8, ["icon"])
                                ]))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.productsResults.length && !_ctx.loadingProducts)
                        ? (_openBlock(), _createBlock(_component_ion_slides, {
                            key: 1,
                            pager: "true",
                            options: _ctx.slideProductOpts,
                            ref: "productsSlider",
                            onIonSlideDidChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.ionSlideDidChange($event)))
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productsResults, (product, index) => {
                                return (_openBlock(), _createBlock(_component_ion_slide, { key: index }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ProductItem, { initProduct: product }, null, 8, ["initProduct"])
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128)),
                              (!_ctx.loadingProducts)
                                ? (_openBlock(), _createBlock(_component_ion_slide, { key: 0 }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_18, [
                                        _createElementVNode("a", {
                                          href: _ctx.getUrl(_ctx.selectedSubCategory.url_path),
                                          textContent: _toDisplayString(
													_ctx.t('moreOf', {
														categoryName: _ctx.selectedSubCategory.name,
													})
												)
                                        }, null, 8, _hoisted_19)
                                      ])
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["options"]))
                        : _createCommentVNode("", true),
                      (!_ctx.productsResults.length && _ctx.loadingProducts)
                        ? (_openBlock(), _createBlock(_component_ion_slides, {
                            key: 2,
                            options: _ctx.slideProductOpts
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (n) => {
                                return _createVNode(_component_ion_slide, { key: n }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_20, [
                                      _createElementVNode("div", _hoisted_21, [
                                        _createElementVNode("div", _hoisted_22, [
                                          _createVNode(_component_ion_skeleton_text, {
                                            animated: true,
                                            style: {"width":"100%","height":"100%"}
                                          })
                                        ])
                                      ]),
                                      _createElementVNode("div", _hoisted_23, [
                                        _createElementVNode("h2", null, [
                                          _createVNode(_component_ion_skeleton_text, {
                                            animated: true,
                                            style: {"width":"100%","height":"100%"}
                                          })
                                        ]),
                                        _createElementVNode("div", _hoisted_24, [
                                          _createVNode(_component_ion_skeleton_text, {
                                            animated: true,
                                            style: {"width":"100%","height":"12px"}
                                          })
                                        ])
                                      ])
                                    ])
                                  ]),
                                  _: 2
                                }, 1024)
                              }), 64))
                            ]),
                            _: 1
                          }, 8, ["options"]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.category['entity_id'])
      ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", {
                class: _normalizeClass(["col-sm-12", { 'col-md-6': _ctx.have2Banners, 'col-md-12': !_ctx.have2Banners }])
              }, [
                _createVNode(_component_Banner, {
                  code: 'home_category_' + _ctx.category['entity_id'] + '_1',
                  page: "home"
                }, null, 8, ["code"])
              ], 2),
              (_ctx.have2Banners)
                ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                    _createVNode(_component_Banner, {
                      code: 'home_category_' + _ctx.category['entity_id'] + '_2',
                      page: "home"
                    }, null, 8, ["code"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}