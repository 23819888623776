
import { IonContent, IonPage } from "@ionic/vue";
import { defineComponent, ref, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
import settingsApp from "@/libs/settings";
import httpApp from "@/libs/http";
import { useLocalStorage } from "@/libs/localStorage";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import MainHeader from "../components/MainHeader.vue";
import Slider from "../components/Slider.vue";
import CategoryProductSlider from "../components/CategoryProductSlider.vue";
//import HomeTopProductList from "../components/HomeTopProductList.vue";
import MainFooter from "../components/MainFooter.vue";

export default defineComponent({
	name: "Home",
	setup() {
		const { t, te } = useI18n({ useScope: "global" });
		const storeX = useStore();
		const { getLocalStorageData } = useLocalStorage();
		const { createLoading, openToast } = useGlobalFunctions();

		const blockCodes = ref([
			"home_category_sections_excluded",
			"free_delivery",
			"newsLetter_content",
		]);
		const homeBlocks = ref([]);

		const gettingHomeSections = () => {
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("cmsSimpleBlocks"), {
					store_view: getLocalStorageData("storeCode"),
					identifiers: blockCodes.value,
				})
				.then(async (response: any) => {
					Object.keys(response).forEach((code) => {
						const responseItem = response[code];
						if (responseItem.success) {
							homeBlocks.value[code] = responseItem.block_data;
						}
					});
				})
				.catch((error) => {
					console.log(error, "error");
				});
		};

		const getHomeBlocksHtml = (code) => {
			if (homeBlocks.value[code]) {
				return homeBlocks.value[code].content;
			}
			return "";
		};

		const newsletter = ref({
			email: "",
		});
		const newsletterRules = computed(() => ({
			email: {
				required,
			},
		}));
		const vNewsletter = useVuelidate(newsletterRules, newsletter) as any;

		const newsletterSubscribeAcction = async (event) => {
			event.preventDefault();
			vNewsletter.value.$touch();
			if (vNewsletter.value.$invalid) {
				return false;
			}
			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("subscribeNewsletter"), {
					email: newsletter.value.email,
				})
				.then((resp: any) => {
					if (resp.success) {
						let msg = t("successMsgs.successSubscribe");
						if (resp.needConfirmation) {
							msg = t("successMsgs.confirmationEmailRegister");
						}
						openToast(msg, "success");
					} else {
						if (te("apiMsgs." + resp.error.code)) {
							openToast(t("apiMsgs." + resp.error.code), "danger");
						} else {
							openToast(resp.error.message, "danger");
						}
					}
					loading.dismiss();
				})
				.catch(() => {
					openToast(t("errorMsgs.errorOccurred"), "danger");
					loading.dismiss();
				});
		};

		const homeProductsSliders = computed(() => {
			if (storeX.state.mainCategories.length) {
				const categoriesIds = storeX.state.mainCategories.map(
					(entity) => entity.entity_id
				);
				if (homeBlocks.value["home_category_sections_excluded"]) {
					if (homeBlocks.value["home_category_sections_excluded"].content) {
						const excludedIds = homeBlocks.value[
							"home_category_sections_excluded"
						].content
							.toString()
							.split(",");
						if (excludedIds.length) {
							const filteredIds = categoriesIds.filter(
								(id) => !excludedIds.includes(id)
							);
							return filteredIds;
						}
					}
				}
				return categoriesIds;
			}

			return [];
		});

		watch(
			() => storeX.state.storeCode,
			() => {
				gettingHomeSections();
			}
		);

		function ionViewWillEnter() {
			gettingHomeSections();
		}

		return {
			t,
			te,
			storeX,
			ionViewWillEnter,
			homeBlocks,
			getHomeBlocksHtml,
			newsletter,
			vNewsletter,
			newsletterSubscribeAcction,
			homeProductsSliders,
		};
	},
	components: {
		IonContent,
		IonPage,
		MainHeader,
		Slider,
		CategoryProductSlider,
		//HomeTopProductList,
		MainFooter,
	},
});
