<template>
  <ul :class="menuClass">
    <li v-for="(menuItem, index) in menuItems" :key="index">
      <a
        :class="{ active: isActive(menuItem.url_path) }"
        :href="getUrl(menuItem.url_path)"
        @click="nav(menuItem.url_path, $event)"
        v-if="!props.useWebRouter"
      >
        {{ menuItem.title }}
        <i v-if="menuItem.children" class="fi-rs-angle-down"></i
      ></a>
      <a
        :class="{ active: isActive(menuItem.url_path) }"
        :href="getUrl(menuItem.url_path)"
        v-if="props.useWebRouter"
      >
        {{ menuItem.title }}
        <i v-if="menuItem.children" class="fi-rs-angle-down"></i
      ></a>
      <ul class="sub-menu" v-if="menuItem.children">
        <li v-for="(children, index) in menuItem.children" :key="index">
          <a
            :href="getUrl(menuItem.url_path)"
            @click="nav(menuItem.url_path, $event)"
            v-text="children.title"
            v-if="!props.useWebRouter"
            :class="{ active: isActive(menuItem.url_path) }"
          ></a>
          <a
            :href="getUrl(menuItem.url_path)"
            v-text="children.title"
            v-if="props.useWebRouter"
            :class="{ active: isActive(menuItem.url_path) }"
          ></a>
        </li>
      </ul>
    </li>
  </ul>
</template>
<script>
import { defineComponent, ref, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "@/store";
import settingsApp from "@/libs/settings";
import httpApp from "@/libs/http";

export default defineComponent({
  name: "Menu",
  props: {
    code: String,
    class: String,
    useWebRouter: Boolean,
  },
  setup(props) {
    const storeX = useStore();
    const router = useRouter();
    const route = useRoute();
    const menuItems = ref([]);

    const menuClass = ref(props.class);
    const menuCode = ref(props.code + "_" + storeX.state.storeCode);

    const getMenu = () => {
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("getMenu"), {
          menu_code: menuCode.value,
        })
        .then(async (response) => {
          if (response.success) {
            if (response.menu_items) {
              menuItems.value = response.menu_items;
            }
            storeX.commit("addMenu", response);
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    };

    function setMenu() {
      const menus = storeX.state.menus;
      if (Object.keys(menus).length) {
        if (menus[menuCode.value]) {
          menuItems.value = menus[menuCode.value].menu_items;
        } else {
          getMenu();
        }
      } else {
        getMenu();
      }
    }

    const getUrl = (path) => {
      return "/" + storeX.state.storeCode + "/" + path;
    };

    const nav = (path, event) => {
      event.preventDefault();
      router.push("/" + storeX.state.storeCode + "/" + path);
    };

    watch(
      () => storeX.state.storeCode,
      () => {
        menuCode.value = props.code + "_" + storeX.state.storeCode;
        setMenu();
      }
    );

    const isActive = (menuPath) => {
      return route.fullPath === "/" + storeX.state.storeCode + "/" + menuPath;
    };

    onMounted(() => {
      setMenu();
    });

    return {
      menuItems,
      menuClass,
      getUrl,
      nav,
      props,
      isActive,
    };
  },
});
</script>
