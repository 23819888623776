import { useLocalStorage } from "./localStorage";
import settingsApp from "./settings";
import httpApp from "./http";
import { store } from "@/store";
import { useGlobalFunctions } from "@/libs/globalFunctions";

import i18n from "@/i18n";
const { t, te } = i18n.global;

const { createLoading, openToast } = useGlobalFunctions();

const { getLocalStorageData } = useLocalStorage();
const storeX = store;

const cartApp = {
	getQuote: async (showLoading = false) => {
		const loading = await createLoading(t("pleaseWait"));
		if (showLoading) {
			loading.present();
		}
		return new Promise(function(resolve, reject) {
			const minimumOrderAmount = getLocalStorageData("minimumAmount")
				? Number(getLocalStorageData("minimumAmount"))
				: 0;
			httpApp
				.sendGetRequest(settingsApp.getEndpointUrl("getQuote"))
				.then((response: any) => {
					if (!Object.prototype.hasOwnProperty.call(response, "error")) {
						const quote = response;
						const subtotal = Number(quote.subtotal);
						const discount =
							Number(subtotal) - Number(quote.subtotal_with_discount);
						const deliveryFees =
							Number(quote.delivery_fees) || Number(quote.shipping_amount);
						const grandTotal = Number(quote.grand_total);
						const walletAmount = Number(quote.epowercrm_wallet_amount);
						const offerAmount = Number(quote.epowercrm_offer_amount);
						const pointsValue = Number(quote.redeem_points_amount);
						const paymentFees = Number(quote.payment_fee);

						const returnData = {
							quote: quote,
							showMinimumMsg: minimumOrderAmount > subtotal ? true : false,
							subtotal: subtotal,
							deliveryFees: deliveryFees,
							discount: discount,
							wallet: walletAmount,
							offer: offerAmount,
							loyalty: pointsValue,
							paymentFees: paymentFees,
							grandTotal: grandTotal,
						};

						storeX.commit("setCartTotals", {
							subtotal: subtotal,
							deliveryFees: deliveryFees,
							discount: discount,
							wallet: walletAmount,
							offer: offerAmount,
							loyalty: pointsValue,
							paymentFees: paymentFees,
							grandTotal: grandTotal,
						});

						if (quote["shipping_address"]["region_id"]) {
							storeX.commit(
								"setShippingStateId",
								quote["shipping_address"]["region_id"]
							);
						}

						resolve(returnData);
					} else {
						reject(response);
					}
				})
				.catch((error) => {
					console.log(error, "error");
					if (showLoading) {
						loading.dismiss();
					}
					reject(error);
				});
		});
	},
	getQuoteItems: async (showLoading = false, addReject = false) => {
		const loading = await createLoading(t("pleaseWait"));
		if (showLoading) {
			loading.present();
		}
		return new Promise(function(resolve, reject) {
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("getQuoteItems"), {
					store_view: storeX.state.storeCode,
				})
				.then((response: any) => {
					if (response.success) {
						const items = response.items;
						const cartItems: any = {};
						let finishedProc = 0;
						for (let i = 0; i < items.length; i++) {
							const item = items[i];
							cartItems["prd_" + item.product_id] = {
								qty: item.qty,
								product_id: item.product_id,
								item_id: item.item_id,
								item: item,
							};
							finishedProc++;
							if (finishedProc == items.length) {
								storeX.commit("setCartItems", cartItems);
								resolve(response.items);
							}
						}
					} else {
						storeX.commit("setCartItems", []);
						if (addReject) reject(response);
					}
					if (showLoading) {
						loading.dismiss();
					}
				})
				.catch((error) => {
					if (showLoading) {
						loading.dismiss();
					}
					if (addReject) reject(error);
				});
		});
	},

	addToCart: async (data: any, showLoading = false, silent = false) => {
		const loading = await createLoading(t("pleaseWait"));
		if (showLoading) {
			loading.present();
		}
		return new Promise(function(resolve, reject) {
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("addToCart"), data)
				.then((response: any) => {
					if (showLoading) {
						loading.dismiss();
					}
					if (!Object.prototype.hasOwnProperty.call(response, "error")) {
						const item = response.item;
						const cartItems = storeX.state.cartItems;
						cartItems["prd_" + data.product_id] = {
							qty: item.qty,
							product_id: item.product_id,
							item_id: item.item_id,
							item: item,
						};
						storeX.commit("setCartItems", cartItems);
						resolve(response);
					} else {
						if (!silent) {
							if (te("apiMsgs." + response.error.code)) {
								openToast(t("apiMsgs." + response.error.code), "danger");
							} else {
								openToast(response.error.message, "danger");
							}
						}
						reject(response);
					}
				})
				.catch((error) => {
					if (showLoading) {
						loading.dismiss();
					}
					reject(error);
				});
		});
	},
	updateCartQty: async (
		product: any,
		qty: any,
		showLoading = false,
		silent = false
	) => {
		const loading = await createLoading(t("pleaseWait"));
		if (showLoading) {
			loading.present();
		}
		return new Promise(function(resolve, reject) {
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("updateQuantityById"), {
					item_id:
						storeX.state.cartItems["prd_" + product.entity_id]["item_id"],
					qty: qty,
				})
				.then((response: any) => {
					if (showLoading) {
						loading.dismiss();
					}
					if (!Object.prototype.hasOwnProperty.call(response, "error")) {
						const item = response.item;
						const cartItems = storeX.state.cartItems;
						cartItems["prd_" + product.entity_id] = {
							qty: item.qty,
							item_id: item.item_id,
							item: item,
						};
						storeX.commit("setCartItems", cartItems);
						resolve(response);
					} else {
						if (!silent) {
							if (te("apiMsgs." + response.error.code)) {
								openToast(t("apiMsgs." + response.error.code), "danger");
							} else {
								openToast(response.error.message, "danger");
							}
						}
						reject(response);
					}
				})
				.catch((error) => {
					if (showLoading) {
						loading.dismiss();
					}
					reject(error);
				});
		});
	},
	removeFromCart: async (
		itemId: any,
		productId: any,
		showLoading = false,
		silent = false
	) => {
		const loading = await createLoading(t("pleaseWait"));
		if (showLoading) {
			loading.present();
		}
		return new Promise(function(resolve, reject) {
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("removeItemCartById"), {
					item_id: itemId,
				})
				.then((response: any) => {
					if (showLoading) {
						loading.dismiss();
					}
					if (!Object.prototype.hasOwnProperty.call(response, "error")) {
						const cartItems = storeX.state.cartItems;
						delete cartItems["prd_" + productId];
						storeX.commit("setCartItems", cartItems);
						resolve(response);
					} else {
						if (!silent) {
							if (te("apiMessages." + response.error.code)) {
								openToast(t("apiMsgs." + response.error.code), "danger");
							} else {
								openToast(response.error.message, "danger");
							}
						}
						reject(response);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	updateItemsCart: async (
		cartItemsQtyData: any,
		showLoading = false,
		silent = false
	) => {
		const loading = await createLoading(t("pleaseWait"));
		if (showLoading) {
			loading.present();
		}
		return new Promise(function(resolve, reject) {
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("updateItemsCart"), {
					cart: cartItemsQtyData,
				})
				.then((response: any) => {
					if (showLoading) {
						loading.dismiss();
					}
					if (!Object.prototype.hasOwnProperty.call(response, "error")) {
						resolve(response);
					} else {
						if (!silent) {
							if (te("apiMsgs." + response.error.code)) {
								openToast(t("apiMsgs." + response.error.code), "danger");
							} else {
								openToast(response.error.message, "danger");
							}
						}
						reject(response);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	applyPromoCode: async (
		couponCode: any,
		showLoading = false,
		silent = false
	) => {
		const loading = await createLoading(t("pleaseWait"));
		if (showLoading) {
			loading.present();
		}
		return new Promise(function(resolve, reject) {
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("applyPromoCode"), {
					coupon_code: couponCode,
				})
				.then((response: any) => {
					if (showLoading) {
						loading.dismiss();
					}
					if (!Object.prototype.hasOwnProperty.call(response, "error")) {
						resolve(response);
					} else {
						if (!silent) {
							if (te("apiMsgs." + response.error.code)) {
								openToast(t("apiMsgs." + response.error.code), "danger");
							} else {
								openToast(response.error.message, "danger");
							}
						}
						reject(response);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
};

export default cartApp;
