
import { defineComponent, ref, onMounted } from "vue";
import settingsApp from "@/libs//settings";
import httpApp from "@/libs/http";
import { useRouter } from "vue-router";
import { useStore } from "@/store";

import { IonImg } from "@ionic/vue";

export default defineComponent({
	name: "Banner",
	props: {
		code: String,
		page: String,
	},
	setup(props) {
		const router = useRouter();
		const storeX = useStore();

		const page = ref(props.page);
		if (!props.page) {
			page.value = "general";
		}

		const banner = ref(null);
		function getBanner() {
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("getBanner"), {
					banner_code: props.code,
				})
				.then(async (response: any) => {
					const resp = response;
					if (resp.success) {
						if (resp.data) {
							banner.value = resp.data;
							resp.data.mobilePage = page.value;
							storeX.commit("addBanner", resp.data);
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}

		function setBanner() {
			const pageString = page.value as string;
			if (storeX.state.banners[pageString]) {
				Object.keys(storeX.state.banners[pageString]).forEach((key, index) => {
					if (key == props.code) {
						banner.value = storeX.state.banners[pageString][key];
					}
					if (
						Object.keys(storeX.state.banners[pageString]).length == index + 1 &&
						!banner.value
					) {
						getBanner();
					}
				});
			} else {
				getBanner();
			}
		}

		const slideHasLink = () => {
			if (
				banner.value.internal_type == "category" ||
				banner.value.internal_type == "product" ||
				banner.value.url_type == "external"
			) {
				if (banner.value.url_type) {
					return true;
				}
			}
			return false;
		};

		const imageClicked = (event: any) => {
			event.preventDefault();
			if (banner.value) {
				if (banner.value.url_type) {
					if (banner.value.url_type == "internal") {
						if (banner.value.url_path) {
							router.push(
								"/" + storeX.state.storeCode + "/" + banner.value.url_path
							);
						}
					} else if (banner.value.url_type == "external") {
						window.open(banner.value.external_url, "_blank");
					} else {
						return false;
					}
				}
			}
		};
		onMounted(() => {
			setBanner();
		});
		return {
			banner,
			imageClicked,
			slideHasLink,
		};
	},
	components: {
		IonImg,
	},
});
