import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!

  return (_ctx.banner)
    ? (_openBlock(), _createBlock(_component_ion_img, {
        key: 0,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.imageClicked($event))),
        class: _normalizeClass([{ hasLink: _ctx.slideHasLink() }, "banner"]),
        src: _ctx.banner.img_url
      }, null, 8, ["class", "src"]))
    : _createCommentVNode("", true)
}