import settingsApp from "./settings";
import httpApp from "./http";
import { store } from "@/store";

const storeX = store;

const notificationApp = {
	getNotifications: (page: number, addReject = false) => {
		return new Promise(function(resolve, reject) {
			httpApp
				.sendPostRequest(
					settingsApp.getEndpointUrl("getNotifications"),
					{
						app_code: "yallatager_pwa",
						limit: 10,
						page: page,
					}
				)
				.then((response: any) => {
					if (response.success) {
						if (response.data.length > 0) {
							const items = response.data;
							let finishedProc = 0;
							if (page == 1) {
								storeX.commit("setNotifications", []);
							}
							storeX.commit("setNotificationsPage", page);
							for (let i = 0; i < items.length; i++) {
								const item = items[i];
								storeX.commit("addNotification", item);

								finishedProc++;
								if (finishedProc == items.length) {
									resolve(response);
								}
							}
						} else {
							storeX.commit("setNotificationsPage", 0);
						}
					} else {
						resolve(true);
					}
				})
				.catch(() => {
					if (addReject) reject();
				});
		});
	},
	getNewNotificationsCount: (addReject = false) => {
		return new Promise(function(resolve, reject) {
			httpApp
				.sendPostRequest(
					settingsApp.getEndpointUrl("getNewNotificationsCount"),
					{
						app_code: "yallatager_pwa",
					}
				)
				.then((response: any) => {
					if (response.success) {
						storeX.commit(
							"setNewNotificationsCount",
							Number(response.new_count)
						);
						resolve(true);
					} else {
						if (addReject) reject();
					}
				})
				.catch(() => {
					if (addReject) reject();
				});
		});
	},
	markAllAsRead: (addReject = false) => {
		return new Promise(function(resolve, reject) {
			httpApp
				.sendPostRequest(
					settingsApp.getEndpointUrl("markAllNotificationsAsRead"),
					{
						app_code: "yallatager_pwa",
					}
				)
				.then((response: any) => {
					if (response.success) {
						resolve(true);
					} else {
						if (addReject) reject();
					}
				})
				.catch(() => {
					if (addReject) reject();
				});
		});
	},
	markAllAsDeleted: (addReject = false) => {
		return new Promise(function(resolve, reject) {
			httpApp
				.sendPostRequest(
					settingsApp.getEndpointUrl("markAllNotificationsAsDeleted"),
					{
						app_code: "yallatager_pwa",
					}
				)
				.then((response: any) => {
					if (response.success) {
						resolve(true);
					} else {
						if (addReject) reject();
					}
				})
				.catch(() => {
					if (addReject) reject();
				});
		});
	},
	markAsRead: (id, addReject = false) => {
		return new Promise(function(resolve, reject) {
			httpApp
				.sendPostRequest(
					settingsApp.getEndpointUrl("markNotificationAsRead"),
					{
						app_code: "yallatager_pwa",
						notification_id: id,
					}
				)
				.then((response: any) => {
					if (response.success) {
						resolve(true);
					} else {
						if (addReject) reject();
					}
				})
				.catch(() => {
					reject();
				});
		});
	},
	markAsDeleted: (id, addReject = false) => {
		return new Promise(function(resolve, reject) {
			httpApp
				.sendPostRequest(
					settingsApp.getEndpointUrl("markNotificationAsDeleted"),
					{
						app_code: "yallatager_pwa",
						notification_id: id,
					}
				)
				.then((response: any) => {
					if (response.success) {
						storeX.commit("removeNotification", id);
						resolve(true);
					} else {
						if (addReject) reject();
					}
				})
				.catch(() => {
					if (addReject) reject();
				});
		});
	},
};

export default notificationApp;
