
import { IonContent, IonPage } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useRouter, useRoute, RouteRecordRaw } from "vue-router";
import MainHeader from "../components/MainHeader.vue";
import MainFooter from "../components/MainFooter.vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";

import httpApp from "@/libs/http";
import settingsApp from "@/libs//settings";

export default defineComponent({
  name: "NotFound",
  setup() {
    const { t } = useI18n({ useScope: "global" });
    const router = useRouter();
    const route = useRoute();
    const storeX = useStore();

    const loading = ref(true);
    const parseUrl = async () => {
      const langCode = storeX.state.storeCode;
      let requestPath = route.fullPath;
      let finalPath = "/:lang(en|ar)/" + requestPath;
      let navPath = "/" + storeX.state.storeCode + "/" + requestPath;
      let pathName = storeX.state.storeCode + "-" + requestPath;

      let fromStore = "";
      const searchParamsArr = requestPath.split("?");
      if (searchParamsArr[1]) {
        const searchParams = "?" + searchParamsArr[1];
        const urlParams = new URLSearchParams(searchParams);
        fromStore = urlParams.get("from_store");
        if (fromStore) navPath += "?from_store=" + fromStore;
      }

      if (route.params['lang']) {
        requestPath = requestPath.replace('/' + route.params['lang'] + '/', '');
      }

      const paramsParse = {
        store_view: storeX.state.storeCode,
        from_store: fromStore,
        request_path: requestPath,
      };

      const paramsString = new URLSearchParams(paramsParse).toString();
      const response = (await httpApp.sendGetRequest(
        settingsApp.getEndpointUrl("parseUrlKey") + "?" + paramsString
      ));
      if (response.success) {
        if (response.data["new_path"]) {
          finalPath = "/:lang(en|ar)/" + response.data["new_path"];
          navPath =
            "/" +
            storeX.state.storeCode +
            "/" +
            response.data["new_path"];
          pathName = langCode + "-" + response.data["new_path"];
        }
        if (searchParamsArr[1]) {
          navPath += "?" + searchParamsArr[1];
        }
        let newRoute;
        if (response.data.object_type == "product") {
          newRoute = {
            path: finalPath,
            name: pathName,
            component: () => import("../views/Product.vue"),
            props: {
              product_id: response.data.object_id,
              lang: langCode,
            },
            params: {
              lang: langCode,
            },
          } as RouteRecordRaw;
        } else if (response.data.object_type == "category") {
          newRoute = {
            path: finalPath,
            name: pathName,
            component: () => import("../views/Category.vue"),
            props: {
              category_id: response.data.object_id,
              lang: langCode,
            },
            params: {
              lang: langCode,
            },
          } as RouteRecordRaw;
        } else if (response.data.object_type == "cms_page") {
          newRoute = {
            path: finalPath,
            name: pathName,
            component: () => import("../views/CMSPage.vue"),
            props: {
              pageId: response.data.object_id,
              pageIdentifier: requestPath,
              lang: langCode,
            },
            params: {
              lang: langCode,
            },
          } as RouteRecordRaw;
        }
        await router.addRoute(newRoute);
        router.replace(navPath);
        location.reload();
      } else {
        loading.value = false;
      }
    }

    const nav = (path, event) => {
      event.preventDefault();
      router.push(path);
    };

    const ionViewWillEnter = () => {
      parseUrl();
    };

    return {
      t,
      nav,
      storeX,
      loading,
      ionViewWillEnter
    };
  },
  components: {
    IonContent,
    IonPage,
    MainHeader,
    MainFooter,
  },
});
