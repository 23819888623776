import { useLocalStorage } from "./localStorage";
import settingsApp from "./settings";
import httpApp from "./http";
const {
	setLocalStorageData,
	getLocalStorageData,
	getAllLocalStorageData,
} = useLocalStorage();
const sessionApp = {
	islogged: () => {
		try {
			const customerData = getLocalStorageData("customerData");

			if (!customerData) {
				return false;
			} else {
				return true;
			}
		} catch (e) {
			return false;
		}
	},

	deleteAll: () => {
		setLocalStorageData({
			customerData: null,
		});
	},

	getCustomerData: () => {
		return new Promise(function(resolve, reject) {
			httpApp
				.sendGetRequest(settingsApp.getEndpointUrl("accountData"))
				.then((response: any) => {
					if (
						!Object.prototype.hasOwnProperty.call(response, "error")
					) {
						setLocalStorageData({
							customerData: response,
						});
						getLocalStorageData("customerData");
						getAllLocalStorageData();
						resolve(response);
					} else {
						sessionApp.deleteAll();
						reject(response);
					}
				})
				.catch((error) => {
					sessionApp.deleteAll();
					reject(error);
				});
		});
	},

	checkSession: (forceNewdata = false) => {
		const customerData = getLocalStorageData("customerData");

		return new Promise(function(resolve, reject) {
			httpApp
				.sendGetRequest(settingsApp.getEndpointUrl("isLogin"))
				.then((response: any) => {
					if (response.success) {
						if (customerData && !forceNewdata) {
							resolve(customerData.customerData);
						} else {
							const getCustomerPromise = sessionApp.getCustomerData();
							getCustomerPromise.then(
								(customerData) => {
									resolve(customerData);
								},
								(customerRes) => {
									reject(customerRes);
								}
							);
						}
					} else {
						sessionApp.deleteAll();
						reject(response);
					}
				})
				.catch((error) => {
					sessionApp.deleteAll();
					reject(error);
				});
		});
	},
};

export default sessionApp;
