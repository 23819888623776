export function useLocalStorage() {
	const localStorageKey = "company_domain-pwa";

	const setLocalStorageData = (newData: any) => {
		let currentData = window.localStorage.getItem(localStorageKey);
		if (currentData) {
			currentData = JSON.parse(currentData);
			Object.assign(currentData, newData);
		} else {
			currentData = newData;
		}
		window.localStorage.setItem(
			localStorageKey,
			JSON.stringify(currentData)
		);
	};

	const getLocalStorageData = (key: any) => {
		let data = null;
		try {
			const currentData = window.localStorage.getItem(localStorageKey);
			let session = null;
			if (currentData) {
				session = JSON.parse(currentData);
				if (Object.prototype.hasOwnProperty.call(session, key)) {
					data = session[key];
				}
			}
		} catch (e) {
			console.log(e);
		}
		return data;
	};

	const getAllLocalStorageData = () => {
		let data = null;
		try {
			const currentData = window.localStorage.getItem(localStorageKey);
			if (currentData) {
				data = JSON.parse(currentData);
			}
		} catch (e) {
			console.log(e);
		}

		return data;
	};

	const deleteAllLocalStorageData = () => {
		try {
			window.localStorage.setItem(localStorageKey, "");
		} catch (e) {
			console.log(e);
		}
	};

	return {
		setLocalStorageData,
		getLocalStorageData,
		getAllLocalStorageData,
		deleteAllLocalStorageData,
	};
}
