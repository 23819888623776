import settingsApp from "./settings";
import httpApp from "./http";
import { store as storeX } from "@/store";
import { useGlobalFunctions } from "@/libs/globalFunctions";

import i18n from "@/i18n";
const { t, te } = i18n.global;

const { createLoading, openToast } = useGlobalFunctions();

const compareApp = {
	getProductsToCompare: async (showLoading = false) => {
		const loading = await createLoading(t("pleaseWait"));
		if (showLoading) {
			loading.present();
		}
		return new Promise(function(resolve) {
			httpApp
				.sendGetRequest(
					settingsApp.getEndpointUrl("getProductToCompare")
				)
				.then(async (response: any) => {
					const resp = response;
					if (resp.success) {
						const items = resp.items;
						const compareItems: any = {};
						const compareCount = resp.items.length;
						let finishedProc = 0;
						for (let i = 0; i < items.length; i++) {
							const item = items[i];
							compareItems["prd_" + item.product_id] = {
								item_id: item.entity_id,
								item: item,
							};
							finishedProc++;
							if (finishedProc == items.length) {
								storeX.commit("setCompareItems", compareItems);
								storeX.commit("setCompareCount", compareCount);

								resolve(response);
							}
						}
					} else {
						storeX.commit("setCompareItems", []);
						storeX.commit("setCompareCount", 0);
					}
					if (showLoading) {
						loading.dismiss();
					}
				})
				.catch(() => {
					if (showLoading) {
						loading.dismiss();
					}
				});
		});
	},

	addToCompare: async (product: any, showLoading = false, silent = false) => {
		const loading = await createLoading(t("pleaseWait"));
		if (showLoading) {
			loading.present();
		}
		return new Promise(function(resolve, reject) {
			httpApp
				.sendPostRequest(
					settingsApp.getEndpointUrl("addProductToCompare"),
					{
						product: product.entity_id,
					}
				)
				.then((response: any) => {
					if (showLoading) {
						loading.dismiss();
					}
					if (
						!Object.prototype.hasOwnProperty.call(response, "error")
					) {
						const compareItems = storeX.state.compareItems;
						compareItems["prd_" + product.entity_id] = {
							item_id: response.item.entity_id,
							item: response.item,
						};
						storeX.commit("setCompareItems", compareItems);
						resolve(response);
					} else {
						if (!silent) {
							if (te("apiMsgs." + response.error.code)) {
								openToast(
									t("apiMsgs." + response.error.code),
									"danger"
								);
							} else {
								openToast(response.error.message, "danger");
							}
						}
						reject(response);
					}
				})
				.catch((error) => {
					if (showLoading) {
						loading.dismiss();
					}
					reject(error);
				});
		});
	},

	removeFromCompare: async (
		product: any,
		showLoading = false,
		silent = false
	) => {
		const loading = await createLoading(t("pleaseWait"));
		if (showLoading) {
			loading.present();
		}
		return new Promise(function(resolve, reject) {
			httpApp
				.sendPostRequest(
					settingsApp.getEndpointUrl("removeProductFromCompare"),
					{
						product: product.entity_id,
					}
				)
				.then((response: any) => {
					if (showLoading) {
						loading.dismiss();
					}
					if (
						!Object.prototype.hasOwnProperty.call(response, "error")
					) {
						const compareItems = storeX.state.compareItems;
						delete compareItems["prd_" + product.entity_id];
						storeX.commit("setCompareItems", compareItems);
						resolve(response);
					} else {
						if (!silent) {
							if (te("apiMsgs." + response.error.code)) {
								openToast(
									t("apiMsgs." + response.error.code),
									"danger"
								);
							} else {
								openToast(response.error.message, "danger");
							}
						}
						reject(response);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
};

export default compareApp;
