
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import settingsApp from "@/libs/settings";
import httpApp from "@/libs/http";
import { useLocalStorage } from "@/libs/localStorage";
import { useStore } from "@/store";
import Menu from "./Menu.vue";

export default defineComponent({
	name: "Footer",
	setup() {
		const { t } = useI18n({ useScope: "global" });
		const storeX = useStore();
		const { getLocalStorageData } = useLocalStorage();

		const blockCodes = ref(["footer-1", "footer-5", "social_media"]);
		const footerBlocks = ref([]);
		const menuCodes = ref([
			"footer_company",
			"footer_corporate",
			"footer_account",
		]);

		const gettingFooterBlocks = () => {
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("cmsBlocks"), {
					store_view: getLocalStorageData("storeCode"),
					identifiers: blockCodes.value,
				})
				.then(async (response: any) => {
					Object.keys(response).forEach((code) => {
						const responseItem = response[code];
						if (responseItem.success) {
							footerBlocks.value[code] = responseItem.block_data;
						}
					});
				})
				.catch((error) => {
					console.log(error, "error");
				});
		};

		const gettingFooterMenus = () => {
			const finalMenuCodes = menuCodes.value.map((code) => {
				const foterMenu = code + "_" + storeX.state.storeCode;
				return foterMenu;
			});
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("getMenus"), {
					store_view: getLocalStorageData("storeCode"),
					menu_codes: finalMenuCodes,
				})
				.then(async (response: any) => {
					Object.keys(response).forEach((code) => {
						const responseItem = response[code];
						if (responseItem.success) {
							storeX.commit("addMenu", responseItem);
						}
					});
				})
				.catch((error) => {
					console.log(error, "error");
				});
		};

		const setMenuCode = (menuCode) => {
			let foterMenu = "";
			if (storeX.state.storeCode == "en") {
				foterMenu = menuCode + "_en";
			} else {
				foterMenu = menuCode + "_ar";
			}
			return foterMenu;
		};

		const getFooterBlocksHtml = (code) => {
			if (footerBlocks.value[code]) {
				return footerBlocks.value[code].content_html;
			}
			return "";
		};
		onMounted(() => {
			gettingFooterBlocks();
			gettingFooterMenus();
		});
		return {
			t,
			footerBlocks,
			getFooterBlocksHtml,
			setMenuCode,
			storeX,
		};
	},
	components: {
		Menu,
	},
});
