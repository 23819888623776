
import { defineComponent, ref, reactive, onMounted, watch, computed } from "vue";
import { IonImg, IonIcon } from "@ionic/vue";

import { arrowDownCircle } from "ionicons/icons";

import { useLocalStorage } from "@/libs/localStorage";
//import { useGlobalFunctions } from "@/libs/globalFunctions";
import settingsApp from "@/libs/settings";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
import httpApp from "@/libs/http";

export default defineComponent({
  name: "CategoriesMegaMenu",
  setup() {

    const { t } = useI18n({ useScope: "global" });
    const storeX = useStore();
    const { getLocalStorageData } = useLocalStorage();

    const icons = reactive({
      arrowDownCircle
    });

    const gettingCategories = ref(false);
    const categories = ref([]);

    const categoriesRows = computed(() => {
      const rows = [];
      let i, l;
      const chunkSize = 3;
      for (i = 0, l = categories.value.length; i < l; i += chunkSize) {
        rows.push(categories.value.slice(i, i + chunkSize));
      }
      return rows;
    });

    const selectedCategory = ref({
      gettingChilds: false,
      category: {
        entity_id: '',
        image: '',
        childs: [],
        name: '',
        url_path: ''
      },
      rowIndx: null
    });

    const getCategoryChilds = (category, silent = false) => {
      if (!silent) {
        selectedCategory.value.gettingChilds = true;
      }
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("categorySubById"), {
          store_view: getLocalStorageData("storeCode"),
          category_id: category.entity_id,
        })
        .then((response) => {
          if (Object.prototype.hasOwnProperty.call(response, "items")) {
            category.childs = response.items;
          } else {
            category.childs = [];
            category.children_count = 0;
          }
          if (!silent) {
            selectedCategory.value.gettingChilds = false;
          }
        })
        .catch((error) => {
          console.log(error);
          if (!silent) {
            selectedCategory.value.gettingChilds = false;
          }
        });
    }

    const getCategoriesChilds = () => {
      categories.value.forEach((category: any) => {
        if (Number(category.children_count) > 0) {
          getCategoryChilds(category, true);
        }
      });
    }

    const getCategories = async (force: boolean, event?: any) => {
      if (storeX.state.mainCategories && categories.value.length == 0) {
        storeX.state.mainCategories.forEach((category: any) => {
          if (!Object.prototype.hasOwnProperty.call(category, 'childs')) {
            category['childs'] = [];
          }
          categories.value.push(category);
        });
      }
      if (categories.value.length == 0 || force) {
        gettingCategories.value = true;
        categories.value = [];
        httpApp
          .sendPostRequest(settingsApp.getEndpointUrl("categoryByLevel"), {
            store_view: getLocalStorageData("storeCode"),
            level: 2,
          })
          .then((response) => {
            let processed = 0;
            response.items.forEach((category: any) => {
              category['childs'] = [];
              categories.value.push(category);
              processed++;
              if (processed == response.items.length) {
                getCategoriesChilds();
              }
            });

            storeX.commit("setMainCategories", response.items);
            if (event) {
              event.target.complete();
            }
            gettingCategories.value = false;
          })
          .catch((error) => {
            if (event) {
              event.target.complete();
            }
            gettingCategories.value = false;
            console.log(error);
          });
      }
    };

    const categoryClicked = (category: any, rowIndx, event) => {
      if (Number(category.children_count) > 0) {
        event.preventDefault();
        if (selectedCategory.value.category['entity_id'] == category.entity_id) {
          selectedCategory.value.rowIndx = null;
          selectedCategory.value.gettingChilds = false;
          selectedCategory.value.category = {
            entity_id: '',
            image: '',
            childs: [],
            name: '',
            url_path: ''
          };

        } else {
          selectedCategory.value.category = {
            entity_id: category.entity_id,
            image: category.image,
            childs: [],
            name: category.name,
            url_path: category.url_path
          };
          selectedCategory.value.category = category;
          selectedCategory.value.rowIndx = rowIndx;
          if (category['childs'].length == 0) {
            getCategoryChilds(category);
          }
        }
        return;
      }
    };

    const showCategory = (category) => {
      return category.include_in_menu == '1';
    }

    const getUrl = (path) => {
      const url = "/" + storeX.state.storeCode + "/" + path;
      return url;
    };

    const getImageUrl = (category: any) => {
      if (category.image) {
        return (
          getLocalStorageData("websiteUrl") +
          "media/catalog/category/" +
          category.image
        );
      } else {
        return "assets/images/placeholder.png";
      }
    };

    watch(
      () => storeX.state.storeCode,
      () => {
        getCategories(true);
      }
    );

    onMounted(() => {
      getCategories(true);
    });

    return {
      t,
      getUrl,
      getImageUrl,
      categoryClicked,
      selectedCategory,
      categoriesRows,
      showCategory,
      icons
    }

  },
  components: {
    IonImg,
    IonIcon
  }
});
