<template>
	<div
		class="cart-dropdown-wrap cart-dropdown-hm2"
		v-if="storeX.state.cartCount"
	>
		<ul>
			<li v-for="(cartItem, cartInx) in storeX.state.cartItems" :key="cartInx">
				<div class="shopping-cart-img">
					<a href="#"
						><img
							:alt="cartItem.item.name"
							:src="getImageUrl(cartItem.item.product_data)"
					/></a>
				</div>
				<div class="shopping-cart-title">
					<h4><a href="#" v-text="cartItem.item.name"></a></h4>
					<h4>
						<span>{{ cartItem.qty }} × </span
						>{{ itemPriceTxt(cartItem.item.base_price) }}
					</h4>
				</div>
				<div class="shopping-cart-delete">
					<a href="javascript: void(0);" @click="removeCartItem(cartItem)"
						><i class="fi-rs-cross-small"></i
					></a>
				</div>
			</li>
		</ul>
		<div class="shopping-cart-footer">
			<div class="shopping-cart-total">
				<h4>
					{{ t("cart.subtotal") }}
					<span v-text="itemPriceTxt(storeX.state.cartTotals.subtotal)"></span>
				</h4>
			</div>
			<div class="shopping-cart-button">
				<a
					href="javascript: void(0);"
					@click="goToCart()"
					class="outline"
					v-text="t('cart.viewCart')"
				></a>
				<a
					href="javascript: void(0);"
					v-if="storeX.state.cartItemsValid"
					@click="goToCheckOut()"
					v-text="t('checkout.checkout')"
				></a>
			</div>
		</div>
	</div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import { useLocalStorage } from "@/libs/localStorage";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import { useStore } from "@/store";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import cartApp from "@/libs/cart";

export default defineComponent({
	name: "MiniCart",
	setup() {
		const { t } = useI18n();
		const storeX = useStore();
		const router = useRouter();
		const { getLocalStorageData } = useLocalStorage();
		const { formatNumberLocale } = useGlobalFunctions();
		const grandTotal = ref(0);
		const subtotal = ref(0);

		const getImageUrl = (product) => {
			if (!product) {
				return "assets/images/placeholder.png";
			}
			if (product["image"] == "no_selection") {
				return "assets/images/placeholder.png";
			} else if (product["image"]) {
				return (
					getLocalStorageData("websiteUrl") +
					"media/catalog/product" +
					product.image
				);
			} else if (product.thumbnail) {
				return (
					getLocalStorageData("websiteUrl") +
					"media/catalog/product" +
					product.thumbnail
				);
			} else if (product.small_image) {
				return (
					getLocalStorageData("websiteUrl") +
					"media/catalog/product" +
					product.small_image
				);
			} else {
				return "assets/images/placeholder.png";
			}
		};

		const getAllQuote = () => {
			cartApp.getQuote().then((result) => {
				grandTotal.value = result.grandTotal;
				subtotal.value = result.subtotal;
			});
		};

		const removeCartItem = async (cartItem) => {
			cartApp
				.removeFromCart(cartItem.item_id, cartItem.item.product_id, true)
				.then(() => {
					cartApp.getQuoteItems();
					getAllQuote();
				});
		};

		const itemPriceTxt = (rowTotal) => {
			const formatRowTotal =
				formatNumberLocale(rowTotal) + " " + storeX.state.currency;
			return formatRowTotal;
		};
		const goToCheckOut = () => {
			router.push("/checkout");
		};
		const goToCart = () => {
			router.push("/cart");
		};

		// onUpdated(() => {
		//   getAllQuote();
		// });
		onMounted(() => {
			getAllQuote();
		});

		return {
			t,
			storeX,
			getImageUrl,
			itemPriceTxt,
			removeCartItem,
			grandTotal,
			subtotal,
			goToCheckOut,
			goToCart,
		};
	},
});
</script>
