import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_slide = _resolveComponent("ion-slide")!
  const _component_ion_slides = _resolveComponent("ion-slides")!

  return (_ctx.slides.length)
    ? (_openBlock(), _createBlock(_component_ion_slides, {
        key: 0,
        pager: "true",
        options: _ctx.slideOpts
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides, (slide, index) => {
            return (_openBlock(), _createBlock(_component_ion_slide, { key: index }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_img, {
                  onClick: ($event: any) => (_ctx.imageClicked($event, slide)),
                  src: _ctx.getSlideImage(slide),
                  class: _normalizeClass({ hasLink: _ctx.slideHasLink(slide) })
                }, null, 8, ["onClick", "src", "class"]),
                _createElementVNode("div", {
                  innerHTML: slide.description
                }, null, 8, _hoisted_1)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["options"]))
    : _createCommentVNode("", true)
}