import { useLocalStorage } from "./localStorage";
import { WEBSITE_URL, FULL_URL, API_KEY, API_CODE } from "./apiConfig";
//import { useGlobalFunctions } from "./globalFunctions";
import { Device } from "@capacitor/device";
import httpApp from "./http";
import { store as storeX } from "@/store";
const { setLocalStorageData, getLocalStorageData } = useLocalStorage();
const domainLangUrl = window.location.origin;
const develop = domainLangUrl.includes("localhost");
const settingsApp = {
	getEndpointUrl: (url: any) => {
		let fullUrl;
		if (develop) {
			fullUrl = getLocalStorageData("websiteUrl");
		} else {
			fullUrl = getLocalStorageData("apiUrl");
		}
		fullUrl += getLocalStorageData("urls")[url];
		return fullUrl;
	},

	setDefaultSettings: async () => {
		let languageCode = getLocalStorageData("storeCode");
		if (!languageCode) {
			const deviceLanguage = await Device.getLanguageCode();
			if (deviceLanguage.value.includes("-")) {
				const languageAr = deviceLanguage.value.split("-");
				languageCode = languageAr[0];
			} else {
				languageCode = deviceLanguage.value;
			}

			if (!languageCode) {
				languageCode = "ar";
			}
		}
		const settingsDarkMode = getLocalStorageData("darkMode");
		if (settingsDarkMode) {
			//document.body.classList.toggle("dark", true);
		}

		const websiteUrl = WEBSITE_URL,
			fullUrl = FULL_URL,
			apiKey = API_KEY,
			apiCode = API_CODE;

		setLocalStorageData({
			apiUrl: fullUrl,
			websiteUrl: websiteUrl,
			apiKey: apiKey,
			apiCode: apiCode,
			urls: {
				getToken: "restfulapi/getPwaToken",
				getStores: "restfulapi/core/websites/getAllStores",
				setStore: "restfulapi/core/websites/setCurrentStore",
				getCurrencies: "restfulapi/core/websites/getCurrentStoreCurrencies",
				setCurrency: "restfulapi/core/websites/setCurrentCurrency",
				parseUrlKey: "restfulapi/core/websites/parseUrlKey",
				getConfigs: "restfulapi/core/config/getConfigByPaths",
				getConfig: "restfulapi/core/config/getConfigByPath",
				productsList: "restfulapi/core/products/getlist",
				productsListByCategory: "restfulapi/core/products/getByCategory",
				productsListByCategoryAndFilters:
					"restfulapi/core/products/getByCategoryAndFilters",
				productsListByAttribute: "restfulapi/core/products/getByAttribute",
				getProductsByType: "restfulapi/core/products/getProductsByType",
				getNewProducts: "restfulapi/core/products/getNewProducts",
				getSaleProducts: "restfulapi/regionpriceapi/products/getSaleProducts",
				getBestSellerProducts: "restfulapi/core/products/getBestSellerProducts",
				getTopRatedProducts: "restfulapi/core/products/getTopRatedProducts",
				productById: "restfulapi/core/products/getItem",
				getproductsothersellers:
					"restfulapi/epowermarketplace/vendor/getproductsothersellers",
				vendorGetdata: "restfulapi/epowermarketplace/vendor/getdata",
				vendorGetproducts: "restfulapi/epowermarketplace/vendor/getproducts",
				getVendorById: "restfulapi/epowermarketplace/vendor/getVendorById",
				getSellerLayerNav:
					"restfulapi/epowermarketplace/vendor/getSellerLayerNav",
				catalogSearch: "restfulapi/core/catalogsearch/search",
				notifyStock: "restfulapi/core/products/notifyStock",
				getAvailableListSorting:
					"restfulapi/core/products/getAvailableListSorting",
				getLayredNavAttributes:
					"restfulapi/core/products/getLayredNavAttributes",
				searchProductsByName: "restfulapi/core/products/searchProductsByName",
				getProductsReviews: "restfulapi/core/products/getProductsReviews",
				getProductsReviewRatings:
					"restfulapi/core/products/getProductsReviewRatings",
				addProductReview: "restfulapi/core/products/addProductReview",
				categoryTree: "restfulapi/core/categories/getTree",
				categoryByLevel: "restfulapi/core/categories/getByLevel",
				categorySubById: "restfulapi/core/categories/getSubById",
				categoryById: "restfulapi/core/categories/getById",
				categoryNotifyHasProducts:
					"restfulapi/core/categories/notifyHasProducts",
				addToCart: "restfulapi/core/cart/addToCart",
				updateQuantity: "restfulapi/core/cart/updateQuantity",
				updateQuantityById: "restfulapi/core/cart/updateQuantityById",
				getQuote: "restfulapi/core/cart/getCurrentQuote",
				getQuoteItems: "restfulapi/core/cart/getQuoteItems",
				removeItemCart: "restfulapi/core/cart/removeItem",
				removeItemCartById: "restfulapi/core/cart/removeItemById",
				removeItemsCart: "restfulapi/core/cart/removeItems",
				updateItemsCart: "restfulapi/core/cart/updateCartQuantities",
				applyPromoCode: "restfulapi/core/cart/applyPromoCode",
				reorder: "restfulapi/core/cart/reorder",
				confirmAccount: "restfulapi/core/customers/confirmAccount",
				validateForgotPasswordToken:
					"restfulapi/core/customers/validateForgotPasswordToken",
				changeForgottenPassword:
					"restfulapi/core/customers/changeForgottenPassword",
				getAllAddresses: "restfulapi/core/customers/getCustomerAllAddresses",
				getDefaultAddresses: "restfulapi/core/customers/getDefaultAddresses",
				getLoginCustomerData: "restfulapi/core/customers/getLoginCustomerData",
				addAddress: "restfulapi/core/customers/addAddress",
				editAddress: "restfulapi/core/customers/editAddress",
				deleteAddress: "restfulapi/core/customers/deleteAddress",
				getAddress: "restfulapi/core/customers/getAddressbyId",
				editCustomer: "restfulapi/core/customers/editCustomer",
				getOrders: "restfulapi/core/customers/getOrders",
				getOrder: "restfulapi/core/customers/getOrder",
				cancelOrder: "restfulapi/core/customers/cancelOrder",
				getWishlistItems: "restfulapi/core/customers/getWishlistItems",
				addWishlistItem: "restfulapi/core/customers/addWishlistItem",
				deleteWishlistItem: "restfulapi/core/customers/deleteWishlistItem",
				getStatesByCountry: "restfulapi/core/geodata/getStatesByCountry",
				getCitiesByState: "restfulapi/core/geodata/getCityByState",
				saveBillingAddress: "restfulapi/core/checkout/saveBillingAddress",
				getPaymentMethods:
					"restfulapi/core/checkout/getAvailablePaymentMethods",
				setPaymentMethod: "restfulapi/core/checkout/setPaymentMethod",
				getShipingMethods: "restfulapi/core/checkout/getAvailableShippingRates",
				setShipingRate: "restfulapi/core/checkout/setShipingRate",
				deliveryTimeSlots: "restfulapi/deliverytimetable/rates/deliverySlots",
				setDeliveryTimeData:
					"restfulapi/deliverytimetable/rates/setDeliveryData",
				placeOrder: "restfulapi/core/checkout/placeOrder",
				mpgsConfiguration: "restfulapi/mpgshc/payment/getJsConfigurations",
				mpgsValidatePayment: "restfulapi/mpgshc/payment/validatePayment",
				mpgsCancelPayment: "restfulapi/mpgshc/payment/cancelPayment",
				stryveCreatePayment: "restfulapi/stryve/payment/createPayment",
				stryveValidatePayment: "restfulapi/stryve/payment/validatePayment",
				stryveCancelPayment: "restfulapi/stryve/payment/cancelPayment",
				paytabsCreatePayment: "restfulapi/paytabs/payment/createPayment",
				paytabsValidatePayment: "restfulapi/paytabs/payment/validatePayment",
				paytabsCancelPayment: "restfulapi/paytabs/payment/cancelPayment",
				fawryCreatePayment: "restfulapi/fawry/payment/createPayment",
				fawryValidatePayment: "restfulapi/fawry/payment/validatePayment",
				fawryCancelPayment: "restfulapi/fawry/payment/cancelPayment",
				sendContactMessage: "restfulapi/core/contact/postForm",
				login: "restfulapi/epowercrm/account/login",
				isLogin: "restfulapi/epowercrm/account/isLogin",
				forgotPassword: "restfulapi/epowercrm/account/forgotPassword",
				accountData: "restfulapi/epowercrm/account/getAccountData",
				logout: "restfulapi/epowercrm/account/logout",
				isMobileRequiredToRegister:
					"restfulapi/epowercrm/account/isMobileRequiredToRegister",
				validateMobileToRegister:
					"restfulapi/epowercrm/account/validateMobileToRegister",
				register: "restfulapi/epowercrm/account/register",
				getFacebookAuthUrl: "restfulapi/epowercrm/account/getFacebookAuthUrl",
				facebookConnect: "restfulapi/epowercrm/account/facebookConnect",
				getFacebookProfile: "restfulapi/epowercrm/account/getFacebookProfile",
				getGoogleAuthUrl: "restfulapi/epowercrm/account/getGoogleAuthUrl",
				googleConnect: "restfulapi/epowercrm/account/googleConnect",
				getGoogleProfile: "restfulapi/epowercrm/account/getGoogleProfile",
				getAppleAuthUrl: "restfulapi/epowercrm/account/getAppleAuthUrl",
				appleConnect: "restfulapi/epowercrm/account/appleConnect",
				getAppleProfile: "restfulapi/epowercrm/account/getAppleProfile",
				isWalletNeededInCheckout:
					"restfulapi/epowercrm/wallet/isNeededInCheckout",
				setWalletBalanceToCart:
					"restfulapi/epowercrm/wallet/setWalletBalanceToCart",
				getWalletTransactions: "restfulapi/epowercrm/wallet/getTransactions",
				getWalletRechargeTransactions:
					"restfulapi/epowercrm/wallet/getRechargeTransactions",
				walletRecharge: "restfulapi/epowercrm/wallet/recharge",
				isLoyaltyNeededInCheckout:
					"restfulapi/epowercrm/loyaltyprogram/isNeededInCheckout",
				loyaltyProgramGetPointsValue:
					"restfulapi/epowercrm/loyaltyprogram/getPointsValue",
				loyaltyProgramSetPointsToCart:
					"restfulapi/epowercrm/loyaltyprogram/setPointsToCart",
				loyaltyProgramGetTransactions:
					"restfulapi/epowercrm/loyaltyprogram/getTransactions",
				editMobile: "restfulapi/epowercrm/account/setMobile",
				verifyVerificationCode:
					"restfulapi/epowercrm/account/verifyVerificationCode",
				resendVerificationCode:
					"restfulapi/epowercrm/account/resendVerificationCode",
				getSupportDepartments: "restfulapi/epowercrm/support/getDepartments",
				getSupportTicketUrgencies:
					"restfulapi/epowercrm/support/getTicketUrgencies",
				getSupportTickets: "restfulapi/epowercrm/support/getTickets",
				getSupportTicketByNo: "restfulapi/epowercrm/support/getTicketByNo",
				getSupportTicketReplies:
					"restfulapi/epowercrm/support/getTicketReplies",
				openSupportTicket: "restfulapi/epowercrm/support/openTicket",
				addSupportTicketReply: "restfulapi/epowercrm/support/addReply",
				rateSupportTicketReply: "restfulapi/epowercrm/support/rateReply",
				cmsPage: "restfulapi/core/cms/getPage",
				cmsBlock: "restfulapi/core/cms/getBlock",
				cmsBlocks: "restfulapi/core/cms/getBlocks",
				cmsSimpleBlock: "restfulapi/core/cms/getSimpleBlock",
				cmsSimpleBlocks: "restfulapi/core/cms/getSimpleBlocks",
				getApp: "restfulapi/appmanagerapi/app/getApp",
				deviceAdd: "restfulapi/appmanagerapi/device/add",
				getSlider: "restfulapi/appmanagerapi/slider/getSlider",
				getBanner: "restfulapi/appmanagerapi/banner/getBanner",
				getNotifications:
					"restfulapi/appmanagerapi/notification/getNotifications",
				getNewNotificationsCount:
					"restfulapi/appmanagerapi/notification/getNewNotificationsCount",
				markAllNotificationsAsRead:
					"restfulapi/appmanagerapi/notification/markAllNotificationsAsRead",
				markAllNotificationsAsDeleted:
					"restfulapi/appmanagerapi/notification/markAllNotificationsAsDeleted",
				markNotificationAsRead:
					"restfulapi/appmanagerapi/notification/markAsRead",
				markNotificationAsDeleted:
					"restfulapi/appmanagerapi/notification/markAsDeleted",
				getMenu: "restfulapi/customization/menu/getMenu",
				getMenus: "restfulapi/customization/menu/getMenus",
				getSellerShippingType:
					"restfulapi/customization/shippingtype/getSellerShippingType",
				isSubscribedNewsletter: "restfulapi/core/newsletters/isSubscribed",
				subscribeNewsletter: "restfulapi/core/newsletters/subscribe",
				unsubscribeNewsletter: "restfulapi/core/newsletters/unsubscribe",
				addProductToCompare: "restfulapi/core/products/addProductToCompare",
				getProductToCompare: "restfulapi/core/products/getProductToCompare",
				removeProductFromCompare:
					"restfulapi/core/products/removeProductFromCompare",
				clearCompare: "restfulapi/core/products/clearCompare",
				getSellerFormData:
					"restfulapi/epowermarketplace/seller/getSellerFormData",
				saveVendor: "restfulapi/epowermarketplace/seller/saveVendor",
				getvendorStatesByCountry: "restfulapi/core/geodata/getStatesByCountry",
				getVendorByRegion:
					"restfulapi/epowermarketplace/vendor/getVendorByRegion",
				getAffilliteAccount:
					"restfulapi/epoweraffiliate/account/getAffilliteAccount",
				createRequest: "restfulapi/epoweraffiliate/account/createRequest",
				createAffiliateSession:
					"restfulapi/epoweraffiliate/account/createAffiliateSession",
				checkReturnableOrder:
					"restfulapi/epowerrmaapi/request/checkReturnableOrders",
				checkReturnableOrderItem:
					"restfulapi/epowerrmaapi/request/OrderItemReturnable",
				handleRMARequest: "restfulapi/epowerrmaapi/request/handleRequest",
				sellerGetProductTemplates:
					"restfulapi/epowermarketplace/seller/gettemplates",
				sellerGetProductTemplateData:
					"restfulapi/epowermarketplace/seller/getTemplateEditableData",
				sellerSaveProductData:
					"restfulapi/epowermarketplace/seller/createrequest",
				sellerGetproducts: "restfulapi/epowermarketplace/seller/getproducts",
				getSellerCommissions:
					"restfulapi/epowermarketplace/seller/getSellerCommissions",
				getSellerCashBack:
					"restfulapi/epowermarketplace/seller/getSellerCashBack",
				getSellerPendingOrders:
					"restfulapi/epowermarketplace/seller/getSellerPendingOrders",
				getSellerPastOrders:
					"restfulapi/epowermarketplace/seller/getSellerPastOrders",
				addBuyerReview: "restfulapi/epowermarketplace/seller/addBuyerReview",
				getBuyerReview: "restfulapi/epowermarketplace/seller/getBuyerReviews",
				getSellerOrderDetails:
					"restfulapi/epowermarketplace/seller/getSellerOrderDetails",
				getCustomerPriceGroups:
					"restfulapi/epowermarketplace/seller/getCustomerGroups",
				getProductEditableData:
					"restfulapi/epowermarketplace/seller/getProductEditableData",
				saveProductEditablePrice:
					"restfulapi/epowermarketplace/seller/saveProductPrice",
				saveProductEditableInventory:
					"restfulapi/epowermarketplace/seller/saveProductInventory",
				getSellerOrderDataBySalesItem:
					"restfulapi/epowermarketplace/customer/getSellerOrderDataBySalesItem",
				getSalesterms: "restfulapi/core/terms/getterms",
				getSellersList: "restfulapi/epowermarketplace/seller/getSellersList",
				scrapperProductPrices: "restfulapi/epowerppws/product/getProductPrices",
				exportProductsQtyPrice:
					"restfulapi/epowermarketplace/seller/exportProductsQtyPrice",
				uploadSellerProductsQtyPriceSheet:
					"restfulapi/epowermarketplace/seller/uploadSellerProductsQtyPriceSheet",
				deleteSellerProduct:
					"restfulapi/epowermarketplace/seller/deleteSellerProduct",
				getAffiliateTransactions:
					"restfulapi/epoweraffiliate/account/getTransactions",
				trackingIsObjectAssigned:
					"restfulapi/epowertrackingcode/trackingcode/isObjectAssigned",
				assignTrackingCode:
					"restfulapi/epowertrackingcode/trackingcode/assignTrackingCode",
				findyotSearch: "restfulapi/findyotapi/search/search",
				findyotGetQuerySuggestions:
					"restfulapi/findyotapi/search/getQuerySuggestions",
				findyotSetSearchRecent: "restfulapi/findyotapi/search/setSearchRecent",
				reportStock: "restfulapi/customization/report/sellerStock",
				reportStockStatistics:
					"restfulapi/customization/report/sellerStockStatistics",
				getWarrantyTypes: "restfulapi/epowerwarranty/type/getTypesForProduct",
				warrantyUploadNationalId:
					"restfulapi/epowerwarranty/certificate/uploadNationalId",
				getWarrantyTypesForItem:
					"restfulapi/epowerwarranty/cart/getTypesForOrderItem",
				addWarrantyOrderedItem:
					"restfulapi/epowerwarranty/cart/addWarrantyOrderedItem",
				getCustomerCertificates:
					"restfulapi/epowerwarranty/certificate/getCustomerCertificates",
				getCustomerCertificateById:
					"restfulapi/epowerwarranty/certificate/getCustomerCertificateById",
				getCustomerClaims: "restfulapi/epowerwarranty/claim/getCustomerClaims",
				getCustomerClaimById: "restfulapi/epowerwarranty/claim/getClaimById",
				addCustomerClaim: "restfulapi/epowerwarranty/claim/addCustomerClaim",
			},
		});
	},

	getWebsiteSettings: () => {
		const configsPromise = new Promise((resolve) => {
			const paths = [
				"sales/minimum_order/amount",
				"general/store_information/phone",
				"general/store_information/merchant_vat_number",
				"general/country/allow",
				"persistent/options/enabled",
				"persistent/options/lifetime",
				"persistent/options/remember_enabled",
				"epowercrm_socialconnect/google/enabled",
				"epowercrm_socialconnect/facebook/api_enabled",
				"epowercrm_socialconnect/twitter/api_enabled",
				"epowercrm_socialconnect/apple/api_enabled",
				"epowercrm_general/signin_signup/required_email",
				"epowercrm_im/smssettings/checkoutrequire",
				"epoweroms_source/website/barcode_attribute_code",
				"customer/address/map_service",
				"customer/address/google_api_key",
				"customization_seller_options/frontend_options/show_sellernamed",
				"customization_general/homepage_options/new_categories_section",
				"customization_product_options/frontend_options/group_price_min",
				"customization_product_options/frontend_options/group_price_max",
				"customization_seller_options/frontend_options/whatsapp_number_code",
				"epower_marketplace_options/general/seller_can_edit_info",
				"egyprotech_findyot/suggestion/is_active",
				"egyprotech_findyot/suggestion/show_products",
				"egyprotech_epowerppws/general/active",
				"epower_emptycategorysubscription/mail/active",
				"payment_fee/payment_fee/default_description",
			];
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("getConfigs"), {
					paths: paths.join(","),
					area: "frontend",
				})
				.then((response: any) => {
					const minimumAmount = response["sales/minimum_order/amount"];
					if (minimumAmount) {
						if (!Object.prototype.hasOwnProperty.call(minimumAmount, "error")) {
							setLocalStorageData({
								minimumAmount: minimumAmount,
							});
						}
					}
					const storeInformationPhone =
						response["general/store_information/phone"];
					if (storeInformationPhone) {
						if (
							!Object.prototype.hasOwnProperty.call(
								storeInformationPhone,
								"error"
							)
						) {
							setLocalStorageData({
								storeInformationPhone: storeInformationPhone,
							});
						}
					}
					const merchantVatNumber =
						response["general/store_information/merchant_vat_number"];
					if (merchantVatNumber) {
						if (
							!Object.prototype.hasOwnProperty.call(merchantVatNumber, "error")
						) {
							setLocalStorageData({
								merchantVatNumber: merchantVatNumber,
							});
						}
					}
					const editVendorDetailsEnabled =
						response["epower_marketplace_options/general/seller_can_edit_info"];
					if (editVendorDetailsEnabled) {
						if (
							!Object.prototype.hasOwnProperty.call(
								editVendorDetailsEnabled,
								"error"
							)
						) {
							setLocalStorageData({
								editVendorDetailsEnabled: editVendorDetailsEnabled,
							});
						}
					}
					const whatsAppNo =
						response[
							"customization_seller_options/frontend_options/whatsapp_number_code"
						];
					if (whatsAppNo) {
						if (!Object.prototype.hasOwnProperty.call(whatsAppNo, "error")) {
							setLocalStorageData({
								whatsAppNo: whatsAppNo,
							});
						}
					}
					const allowedCountries = response["general/country/allow"];
					if (allowedCountries) {
						if (
							!Object.prototype.hasOwnProperty.call(allowedCountries, "error")
						) {
							setLocalStorageData({
								allowedCountries: allowedCountries,
							});
						}
					}
					const persistentEnabled = response["persistent/options/enabled"];
					if (persistentEnabled) {
						if (
							!Object.prototype.hasOwnProperty.call(persistentEnabled, "error")
						) {
							setLocalStorageData({
								persistentEnabled: persistentEnabled,
							});
						}
					}
					const persistentLifetime = response["persistent/options/lifetime"];
					if (persistentLifetime) {
						if (
							!Object.prototype.hasOwnProperty.call(persistentLifetime, "error")
						) {
							setLocalStorageData({
								persistentLifetime: persistentLifetime,
							});
						}
					}
					const persistentRememberEnabled =
						response["persistent/options/remember_enabled"];
					if (persistentRememberEnabled) {
						if (
							!Object.prototype.hasOwnProperty.call(
								persistentRememberEnabled,
								"error"
							)
						) {
							setLocalStorageData({
								persistentRememberEnabled: persistentRememberEnabled,
							});
						}
					}
					const googleLogin =
						response["epowercrm_socialconnect/google/api_enabled"];
					if (googleLogin) {
						if (!Object.prototype.hasOwnProperty.call(googleLogin, "error")) {
							setLocalStorageData({
								googleLogin: googleLogin,
							});
						}
					}
					const facebookLogin =
						response["epowercrm_socialconnect/facebook/api_enabled"];
					if (facebookLogin) {
						if (!Object.prototype.hasOwnProperty.call(facebookLogin, "error")) {
							setLocalStorageData({
								facebookLogin: facebookLogin,
							});
						}
					}
					const twitterLogin =
						response["epowercrm_socialconnect/twitter/api_enabled"];
					if (twitterLogin) {
						if (!Object.prototype.hasOwnProperty.call(twitterLogin, "error")) {
							setLocalStorageData({
								twitterLogin: twitterLogin,
							});
						}
					}
					const appleLogin =
						response["epowercrm_socialconnect/apple/api_enabled"];
					if (appleLogin) {
						if (!Object.prototype.hasOwnProperty.call(appleLogin, "error")) {
							setLocalStorageData({
								appleLogin: appleLogin,
							});
						}
					}
					const signupReuireEmail =
						response["epowercrm_general/signin_signup/required_email"];
					if (signupReuireEmail) {
						if (
							!Object.prototype.hasOwnProperty.call(signupReuireEmail, "error")
						) {
							setLocalStorageData({
								signupReuireEmail: signupReuireEmail,
							});
						}
					}
					const checkoutRequireMobile =
						response["epowercrm_im/smssettings/checkoutrequire"];
					if (checkoutRequireMobile) {
						if (
							!Object.prototype.hasOwnProperty.call(
								checkoutRequireMobile,
								"error"
							)
						) {
							setLocalStorageData({
								checkoutRequireMobile: checkoutRequireMobile,
							});
						}
					}
					const barcodeAttributeCode =
						response["epoweroms_source/website/barcode_attribute_code"];
					if (barcodeAttributeCode) {
						if (
							!Object.prototype.hasOwnProperty.call(
								barcodeAttributeCode,
								"error"
							)
						) {
							setLocalStorageData({
								barcodeAttributeCode: barcodeAttributeCode,
							});
						}
					}
					const mapService = response["customer/address/map_service"];
					if (mapService) {
						if (!Object.prototype.hasOwnProperty.call(mapService, "error")) {
							setLocalStorageData({
								mapService: mapService,
							});
						}
					}
					const addressGoogleApiKey =
						response["epoweroms_source/website/google_api_key"];
					if (addressGoogleApiKey) {
						if (
							!Object.prototype.hasOwnProperty.call(
								addressGoogleApiKey,
								"error"
							)
						) {
							setLocalStorageData({
								addressGoogleApiKey: addressGoogleApiKey,
							});
						}
					}
					const reviewAllowGuest = response["catalog/review/allow_guest"];
					if (reviewAllowGuest) {
						if (
							!Object.prototype.hasOwnProperty.call(reviewAllowGuest, "error")
						) {
							setLocalStorageData({
								reviewAllowGuest: reviewAllowGuest,
							});
						}
					}
					const showSellerName =
						response[
							"customization_seller_options/frontend_options/show_sellernamed"
						];
					if (showSellerName) {
						if (
							!Object.prototype.hasOwnProperty.call(showSellerName, "error")
						) {
							setLocalStorageData({
								showSellerName: showSellerName == "1" ? true : false,
							});
						}
					}

					const groupPriceMin =
						response[
							"customization_product_options/frontend_options/group_price_min"
						];
					if (groupPriceMin) {
						if (!Object.prototype.hasOwnProperty.call(groupPriceMin, "error")) {
							setLocalStorageData({
								groupPriceMin: groupPriceMin,
							});
						}
					}

					const groupPriceMax =
						response[
							"customization_product_options/frontend_options/group_price_max"
						];
					if (groupPriceMax) {
						if (!Object.prototype.hasOwnProperty.call(groupPriceMax, "error")) {
							setLocalStorageData({
								groupPriceMax: groupPriceMax,
							});
						}
					}

					const homeNewCategories =
						response[
							"customization_general/homepage_options/new_categories_section"
						];
					if (homeNewCategories) {
						if (
							!Object.prototype.hasOwnProperty.call(homeNewCategories, "error")
						) {
							setLocalStorageData({
								homeNewCategories: homeNewCategories,
							});
						}
					}

					const findYotActive =
						response["egyprotech_findyot/suggestion/is_active"];
					if (findYotActive) {
						if (!Object.prototype.hasOwnProperty.call(findYotActive, "error")) {
							setLocalStorageData({
								findYotActive: findYotActive == "1" ? true : false,
							});
						}
					}

					const findYotShowProducts =
						response["egyprotech_findyot/suggestion/show_products"];
					if (findYotShowProducts) {
						if (
							!Object.prototype.hasOwnProperty.call(
								findYotShowProducts,
								"error"
							)
						) {
							setLocalStorageData({
								findYotShowProducts: findYotShowProducts == "1" ? true : false,
							});
						}
					}

					const PPWSActive = response["egyprotech_epowerppws/general/active"];
					if (PPWSActive) {
						if (!Object.prototype.hasOwnProperty.call(PPWSActive, "error")) {
							setLocalStorageData({
								PPWSActive: PPWSActive == "1" ? true : false,
							});
						}
					}

					const emptycategorysubscriptionActive =
						response["epower_emptycategorysubscription/mail/active"];
					if (emptycategorysubscriptionActive) {
						if (!Object.prototype.hasOwnProperty.call(PPWSActive, "error")) {
							setLocalStorageData({
								emptycategorysubscriptionActive:
									emptycategorysubscriptionActive == "1" ? true : false,
							});
						}
					}

					const paymentFeeTitle =
						response["payment_fee/payment_fee/default_description"];
					if (PPWSActive) {
						if (
							!Object.prototype.hasOwnProperty.call(paymentFeeTitle, "error")
						) {
							setLocalStorageData({
								paymentFeeTitle: paymentFeeTitle,
							});
						}
					}

					resolve(true);
				})
				.catch((error) => {
					if (error.status == 403) {
						if (Object.prototype.hasOwnProperty.call(error, "error")) {
							const data = JSON.parse(error.error);
							if (data.error.code == "304") {
								setLocalStorageData({
									apiUrl: null,
									apiKey: null,
									apiCode: null,
								});
							}
						}
					}
					console.log("getConfigs", error);
				});
		});

		new Promise((resolve, reject) => {
			httpApp
				.sendGetRequest(settingsApp.getEndpointUrl("getStores"))
				.then((response: any) => {
					if (response.items) {
						const stores: any = [];
						let baseStore: any;
						response.items.forEach(function(storeview: any, i: number) {
							if (storeview.is_active == "1" && storeview.website_id == "1") {
								stores.push({
									code: storeview.code,
									name: storeview.name,
								});
								if (!getLocalStorageData("storeCode")) {
									setLocalStorageData({
										storeCode: storeview.code,
									});
									storeX.commit("setStoreCode", storeview.code);
								}
								baseStore = storeview;
							}
							if (response.items.length == i + 1) {
								setLocalStorageData({
									stores: stores,
								});
								resolve({
									stores,
									baseStore,
								});
							}
						});
					} else {
						reject(response.data);
					}
				})
				.catch((error) => {
					reject(error);
					console.log("getStores", error);
				});
		});

		new Promise((resolve, reject) => {
			httpApp
				.sendGetRequest(settingsApp.getEndpointUrl("getCurrencies"))
				.then((response: any) => {
					if (response.items) {
						const currencies: any = [];
						const currentCurrency = getLocalStorageData("currency");
						let currentCurrencyExist = false;
						let baseCurrency: any = null;
						response.items.forEach(function(currency: any, i: number) {
							currencies.push({
								code: currency.code,
								name: currency.name,
							});
							if (!getLocalStorageData("currency") && currency.is_base) {
								setLocalStorageData({
									currency: currency.code,
								});
							}
							if (currency.code == currentCurrency) {
								currentCurrencyExist = true;
							}
							if (currency.is_base) {
								baseCurrency = currency.code;
							}
							if (response.items.length == i + 1) {
								setLocalStorageData({
									currencies: currencies,
								});
								if (!currentCurrencyExist) {
									setLocalStorageData({
										currency: baseCurrency,
									});
								}
								resolve({ currencies, baseCurrency });
							}
						});
					} else {
						resolve(true);
					}
				})
				.catch((error) => {
					reject(error);
					console.log("getCurrencies", error);
				});
		});
		const domainLangUrl = window.location.origin;
		const isAE = domainLangUrl.includes("ae.yallatager");
		const countryCode = isAE ? "AE" : "EG";
		new Promise((resolve, reject) => {
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("getStatesByCountry"), {
					country_id: countryCode,
					store_view: storeX.state.storeCode,
				})
				.then(async (response: any) => {
					if (!Object.prototype.hasOwnProperty.call(response, "error")) {
						storeX.commit("setStates", response.items);
						resolve(response.items);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});

		return Promise.all([configsPromise]);
	},

	setStates: () => {
		const domainLangUrl = window.location.origin;
		const isAE = domainLangUrl.includes("ae.yallatager");
		const countryCode = isAE ? "AE" : "EG";
		new Promise((resolve, reject) => {
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("getStatesByCountry"), {
					country_id: countryCode,
					store_view: storeX.state.storeCode,
				})
				.then(async (response: any) => {
					if (!Object.prototype.hasOwnProperty.call(response, "error")) {
						storeX.commit("setStates", response.items);
						resolve(response.items);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	setStoreToSession: (storeCode: string) => {
		return new Promise(function(resolve, reject) {
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("setStore"), {
					store_code: storeCode,
				})
				.then((response: any) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
					console.log("setStore", error);
				});
		});
	},

	getVendorData: () => {
		return new Promise((resolve, reject) => {
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("getSellerFormData"), {
					store_view: storeX.state.storeCode,
				})
				.then(async (response: any) => {
					const resp = response;
					if (resp.success) {
						storeX.commit("setSellerData", resp);
						resolve(true);
					} else {
						storeX.commit("setSellerData", null);
						reject(true);
					}
				})
				.catch((error) => {
					console.log(error, "error");
				});
		});
	},

	setPwaToken: async () => {
		let pwaDone;
		if (develop) {
			return true;
		}
		try {
			const response = await fetch(settingsApp.getEndpointUrl("getToken"), {
				credentials: "include",
				method: "GET",
			});
			const returnData = await response.json();
			if (returnData["Pwa-Code"] && returnData["Pwa-Token"]) {
				storeX.commit("setPwaToken", returnData["Pwa-Token"]);
				storeX.commit("setPwaCode", returnData["Pwa-Code"]);
				setLocalStorageData({
					pwaToken: returnData["Pwa-Token"],
				});
				setLocalStorageData({
					pwaCode: returnData["Pwa-Code"],
				});
				pwaDone = true;
			}
		} catch (error) {
			console.log(error);
			pwaDone = false;
		}
		return pwaDone;
	},

	initialize: async () => {
		settingsApp.setDefaultSettings();
		// Get PWA Token
		await settingsApp.setPwaToken();
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				if (getLocalStorageData("apiUrl")) {
					settingsApp.getWebsiteSettings();
					resolve(true);
				} else {
					settingsApp.getWebsiteSettings().then(
						(responses) => {
							resolve(responses);
						},
						() => {
							reject();
						}
					);
				}
			}, 200);
		});
	},
};

export default settingsApp;
