
import { defineComponent, ref, onMounted, computed } from "vue";
import {
  IonPopover,
  IonContent,
  IonCard,
  IonCardContent,
  IonThumbnail,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/vue";
import { close } from "ionicons/icons";

import notificationApp from "@/libs/notification";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";

export default defineComponent({
  name: "Notifications",
  setup() {
    const { t } = useI18n();
    const storeX = useStore();

    const notificationsIsOpenRef = ref(false);
    const notificationsEvent = ref();
    const notificationsSetOpen = (state: boolean, ev?: Event) => {
      notificationsEvent.value = ev;
      notificationsIsOpenRef.value = state;
    };

    const pages = ref(1);

    const icons = ref({
      close,
    });

    const notifications = computed(() => {
      const curNotifications = storeX.state.notifications;
      curNotifications.sort((a: any, b: any) => b.entity_id - a.entity_id);
      curNotifications.filter((obj) => {
        return obj.is_deleted == "0";
      });
      return curNotifications;
    });

    const getNotifications = (event?: any, emptyNotifications = false) => {
      if (emptyNotifications) {
        storeX.commit("setNotificationsPage", 0);
      }
      storeX.commit("setNotificationsPage", storeX.state.notificationsPage + 1);
      if (storeX.state.notificationsPage > pages.value) {
        if (event) {
          event.target.complete();
        }
        return;
      }

      notificationApp
        .getNotifications(storeX.state.notificationsPage)
        .then((resp: any) => {
          if (resp.success) {
            pages.value = Number(resp.pager.pages);
          }
          if (event) {
            event.target.complete();
            if (pages.value == storeX.state.notificationsPage) {
              event.target.disabled = true;
            } else {
              event.target.disabled = false;
            }
          }
        });
    };

    const notificationClicked = (notification) => {
      notificationApp.markAsRead(notification.entity_id).then(() => {
        notification.is_read = "1";
        notificationApp.getNewNotificationsCount(false);
      });
    };

    const actionClicked = (notification: any, action: any) => {
      notificationClicked(notification);
    };

    const deleteNotification = (notification) => {
      notificationApp.markAsDeleted(notification.entity_id).then(() => {
        notification.is_read = "1";
        notification.is_deleted = "1";
        notificationApp.getNotifications(1);
        notificationApp.getNewNotificationsCount(false);
      });
    };

    const getNewCount = () => {
      notificationApp.getNewNotificationsCount(false);
    };

    onMounted(() => {
      getNotifications();
      getNewCount();
    });

    return {
      t,
      storeX,
      notificationsIsOpenRef,
      notificationsSetOpen,
      notificationsEvent,
      getNotifications,
      notifications,
      icons,
      notificationClicked,
      deleteNotification,
      actionClicked,
      pages,
    };
  },
  components: {
    IonPopover,
    IonContent,
    IonCard,
    IonCardContent,
    IonThumbnail,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
  },
});
