
import { defineComponent, ref, reactive, watch } from "vue";
import { IonInput } from "@ionic/vue";
import { add, remove, heartOutline, heart } from "ionicons/icons";
//import { useRouter, RouteRecordRaw } from "vue-router";
import { useRouter } from "vue-router";
import httpApp from "@/libs/http";
import settingsApp from "@/libs/settings";
import { useI18n } from "vue-i18n";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import { useLocalStorage } from "@/libs/localStorage";
import cartApp from "@/libs/cart";
import wishlistApp from "@/libs/wishlist";
import { useStore } from "@/store";
export default defineComponent({
	name: "Home",
	props: {
		initProduct: Object,
		addToCartIcon: Boolean,
		logSearch: Boolean,
		searchQuery: String,
	},
	components: {
		IonInput,
	},
	setup(props) {
		const { t } = useI18n();
		const product = ref(props.initProduct);
		const productCartIcon = ref(props.addToCartIcon);
		const productCartOptions = ref({});
		const addingToCart = ref(false);
		const addingToWishlist = ref(false);

		const { compareDate, createLoading, openToast } = useGlobalFunctions();
		const { getLocalStorageData } = useLocalStorage();

		const storeX = useStore();
		const router = useRouter();
		const icons = reactive({
			add,
			remove,
			heartOutline,
			heart,
		});

		const qty = ref(0);
		if (storeX.state.cartItems["prd_" + product.value.entity_id]) {
			if (storeX.state.cartItems["prd_" + product.value.entity_id]["qty"]) {
				qty.value =
					storeX.state.cartItems["prd_" + product.value.entity_id]["qty"];
			}
		}
		watch(
			() => storeX.state.cartItems,
			() => {
				if (storeX.state.cartItems["prd_" + product.value.entity_id]) {
					if (storeX.state.cartItems["prd_" + product.value.entity_id]["qty"]) {
						qty.value =
							storeX.state.cartItems["prd_" + product.value.entity_id]["qty"];
						return;
					}
				}
				qty.value = 0;
			},
			{ deep: true }
		);

		const getProductUrl = () => {
			let url = "/" + storeX.state.storeCode;
			if (product.value.url_path_category) {
				url += "/" + product.value.url_path_category;
			} else {
				url += "/" + product.value.url_path;
			}
			return url;
		};

		const goToProduct = (event: any = false) => {
			if (event) {
				event.preventDefault();
			}
			if (props.logSearch) {
				httpApp
					.sendPostRequest(
						settingsApp.getEndpointUrl("findyotSetSearchRecent"),
						{
							query: props.searchQuery,
							product_id: product.value.entity_id,
						}
					)
					.then(() => {
						window.location.href = getProductUrl();
					})
					.catch(() => {
						window.location.href = getProductUrl();
					});
			} else {
				window.location.href = getProductUrl();
			}
			/*
      const newRoute = {
        path: "/:lang(en|ar)/" + product.value.url_path_category,
        name: storeX.state.storeCode + "-" + product.value.url_path_category,
        component: () => import("../views/Product.vue"),
        props: {
          product_id: product.value.entity_id,
          lang: storeX.state.storeCode,
        },
      } as RouteRecordRaw;
      router.addRoute(newRoute);
      router.push({
        name: storeX.state.storeCode + "-" + product.value.url_path_category,
      });
      */
		};

		const getWishlistItems = () => {
			wishlistApp
				.getWishlistItems()
				.then(() => {
					addingToWishlist.value = false;
				})
				.catch(() => {
					addingToWishlist.value = false;
				});
		};

		const addToWishlist = () => {
			addingToWishlist.value = true;
			wishlistApp
				.addToWishlist(product.value)
				.then(() => {
					getWishlistItems();
				})
				.catch(() => {
					addingToWishlist.value = false;
				});
		};

		const removeFromWishlist = (itemId: any) => {
			addingToWishlist.value = true;
			wishlistApp
				.removeFromWishlist(itemId)
				.then(() => {
					getWishlistItems();
				})
				.catch((error) => {
					addingToWishlist.value = false;
					console.log(error);
				});
		};
		const addToCart = async () => {
			if (product.value.has_options == "1") {
				goToProduct();
			} else {
				addingToCart.value = true;
				try {
					await cartApp.addToCart({
						product_id: product.value.entity_id,
						qty: 1,
					});
					addingToCart.value = false;
					qty.value = 1;
				} catch (error) {
					addingToCart.value = false;
				}
			}
		};
		const updateCartQty = async (qty: any) => {
			try {
				addingToCart.value = true;
				await cartApp.updateCartQty(product.value, qty);
				addingToCart.value = false;
			} catch (error) {
				addingToCart.value = false;
			}
		};
		const removeFromCart = async () => {
			try {
				addingToCart.value = true;
				const itemId =
					storeX.state.cartItems["prd_" + product.value.entity_id]["item_id"];
				await cartApp.removeFromCart(itemId, product.value.entity_id);
				addingToCart.value = false;
			} catch (error) {
				addingToCart.value = false;
			}
		};
		const minusQty = () => {
			if (qty.value == 0) {
				return;
			}
			if (qty.value == 1) {
				qty.value = 0;
				removeFromCart();
			} else {
				qty.value -= 1;
			}
		};

		const addQty = async () => {
			if (qty.value < Number(product.value.stock_data.qty)) {
				qty.value += 1;
			} else {
				await openToast(
					t("cart.continueFromOthers"),
					"warning",
					true,
					10000,
					"bottom",
					"baseline",
					[
						{
							text: t("ok"),
							side: "end",
							handler: () => {
								goToProduct();
							},
						},
					]
				);
			}
		};
		const getImageUrl = () => {
			if (product.value.image == "no_selection") {
				return "assets/images/placeholder.png";
			} else if (product.value.image) {
				return (
					getLocalStorageData("websiteUrl") +
					"media/catalog/product" +
					product.value.image
				);
			} else if (product.value.thumbnail) {
				return (
					getLocalStorageData("websiteUrl") +
					"media/catalog/product" +
					product.value.thumbnail
				);
			} else if (product.value.small_image) {
				return (
					getLocalStorageData("websiteUrl") +
					"media/catalog/product" +
					product.value.small_image
				);
			} else {
				return "assets/images/placeholder.png";
			}
		};
		const getOffer = () => {
			if (product.value.special_price) {
				const now = new Date();
				const fromDate = product.value.special_from_date;
				const toDate = product.value.special_to_date;
				if (
					(!fromDate && !toDate) ||
					(!fromDate && compareDate(now, toDate) == -1) ||
					(!toDate && compareDate(now, fromDate) == 1) ||
					(fromDate &&
						toDate &&
						compareDate(now, toDate) == -1 &&
						compareDate(now, fromDate) == 1)
				) {
					let offer =
						(Number(product.value.special_price) /
							Number(product.value.price)) *
						100;
					offer = Math.ceil(offer);

					return 100 - offer;
				}
			} else {
				return false;
			}
		};
		const getOfferTxt = () => {
			const offer = getOffer();
			const txt = offer + "%";
			return txt;
		};
		const getPrice = () => {
			const offer = getOffer();
			let price = Number(product.value.final_price) + 0;
			if (offer) {
				price = Number(product.value.special_price) + 0;
			}

			return price;
		};
		const getOldPrice = () => {
			const price = Number(product.value.price) + 0;
			return price;
		};

		const haveQtyCart = () => {
			if (storeX.state.cartItems["prd_" + product.value.entity_id]) {
				if (storeX.state.cartItems["prd_" + product.value.entity_id]["qty"]) {
					return storeX.state.cartItems["prd_" + product.value.entity_id][
						"qty"
					];
				}
			}
			return 0;
		};

		const getProductRating = (product) => {
			let rating = 0;
			if (product["review_summary"]) {
				rating = product.review_summary.rating_summary;
			}
			if (rating > 0) {
				return rating + "%";
			}
			return 0 + "%";
		};

		const convertQtyArabicToEngligh = (value) => {
			qty.value = Number(
				value.replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d))
			);
		};

		const addUpdateCart = async () => {
			if (haveQtyCart()) {
				await updateCartQty(qty.value);
			} else {
				await addToCart();
			}
		};

		const goToCheckOut = () => {
			router.push("/checkout");
		};

		const buyNow = async () => {
			if (qty.value == 0) {
				qty.value += 1;
			}
			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			await addUpdateCart();
			loading.dismiss();
			goToCheckOut();
		};

		const nav = (path, event) => {
			event.preventDefault();
			router.push(path);
		};

		return {
			t,
			product,
			productCartIcon,
			productCartOptions,
			storeX,
			icons,
			getImageUrl,
			getOffer,
			getOfferTxt,
			getPrice,
			getOldPrice,
			addToWishlist,
			removeFromWishlist,
			addToCart,
			goToProduct,
			getProductUrl,
			addingToCart,
			addingToWishlist,
			addQty,
			minusQty,
			haveQtyCart,
			getProductRating,
			nav,
			convertQtyArabicToEngligh,
			qty,
			addUpdateCart,
			buyNow,
		};
	},
});
