import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  key: 0,
  class: "col-md-4 col-sm-6"
}
const _hoisted_3 = ["href", "onClick"]
const _hoisted_4 = { class: "megaMenuItem-title" }
const _hoisted_5 = {
  key: 0,
  class: "row"
}
const _hoisted_6 = {
  key: 0,
  class: "col-md-3 col-sm-6"
}
const _hoisted_7 = ["href", "onClick"]
const _hoisted_8 = {
  key: 0,
  class: "col-md-3 col-sm-6"
}
const _hoisted_9 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoriesRows, (row, rowIndx) => {
    return (_openBlock(), _createElementBlock(_Fragment, { key: rowIndx }, [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (category, catIndex) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: catIndex }, [
            (_ctx.showCategory(category))
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("a", {
                    class: _normalizeClass(["megaMenuItem megaMenuParent", { 'active': category.entity_id == _ctx.selectedCategory.category.entity_id }]),
                    href: _ctx.getUrl(category.url_path),
                    onClick: ($event: any) => (_ctx.categoryClicked(category, rowIndx, $event))
                  }, [
                    _createVNode(_component_ion_img, {
                      src: _ctx.getImageUrl(category)
                    }, null, 8, ["src"]),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(category.name), 1),
                    (category.childs.length)
                      ? (_openBlock(), _createBlock(_component_ion_icon, {
                          key: 0,
                          class: "megaMenuItem-icon",
                          icon: _ctx.icons.arrowDownCircle,
                          color: "primary"
                        }, null, 8, ["icon"]))
                      : _createCommentVNode("", true)
                  ], 10, _hoisted_3)
                ]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]),
      (_ctx.selectedCategory.rowIndx == rowIndx)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["subCategories", { 'hiding': _ctx.selectedCategory.rowIndx == null }])
          }, [
            (_ctx.selectedCategory.category.childs.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_ctx.showCategory(_ctx.selectedCategory.category))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createElementVNode("a", {
                          class: "megaMenuItem",
                          href: _ctx.getUrl(_ctx.selectedCategory.category.url_path),
                          onClick: ($event: any) => (_ctx.categoryClicked(_ctx.selectedCategory, rowIndx, $event))
                        }, [
                          _createVNode(_component_ion_img, {
                            src: _ctx.getImageUrl(_ctx.selectedCategory.category)
                          }, null, 8, ["src"]),
                          _createTextVNode(" " + _toDisplayString(_ctx.t('all')) + " " + _toDisplayString(_ctx.selectedCategory['name']), 1)
                        ], 8, _hoisted_7)
                      ]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedCategory.category.childs, (subCategory, subIndx) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: subIndx }, [
                      (_ctx.showCategory(subCategory))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createElementVNode("a", {
                              class: "megaMenuItem",
                              href: _ctx.getUrl(subCategory['url_path'])
                            }, [
                              _createVNode(_component_ion_img, {
                                src: _ctx.getImageUrl(subCategory)
                              }, null, 8, ["src"]),
                              _createTextVNode(" " + _toDisplayString(subCategory['name']), 1)
                            ], 8, _hoisted_9)
                          ]))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true)
    ], 64))
  }), 128))
}