
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { close } from "ionicons/icons";
import { defineComponent, ref, provide } from "vue";
import { useI18n } from "vue-i18n";
import { useLocalStorage } from "./libs/localStorage";
import settingsApp from "./libs/settings";
import sessionApp from "./libs/session";
import httpApp from "@/libs/http";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import { useStore } from "@/store";
import cartApp from "./libs/cart";
import compareApp from "./libs/comparelist";
import wishlistApp from "./libs/wishlist";
import notificationApp from "@/libs/notification";
import { useRoute } from "vue-router";
import {
  setOptions as setGtagOptions,
  bootstrap as gtagBootstrap,
} from "vue-gtag";

import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";

export default defineComponent({
  name: "App",
  setup() {
    const { locale } = useI18n({ useScope: "global" });
    const storeX = useStore();
    const { setLocalStorageData } = useLocalStorage();
    const { compareVersion, getAppVersion, registerDevice, openToast } =
      useGlobalFunctions();

    const route = useRoute();
    //const router = useRouter();

    const appInit = ref(false);

    const setLanguageLayout = (
      selectedStoreCode?: any,
      setStoreSession = true
    ) => {
      const domainLangUrl = window.location.origin;
      const isAE = domainLangUrl.includes("ae.yallatager");
      const countryCode = isAE ? "AE" : "EG";
      let finalStoreCode = storeX.state.storeCode;
      if (selectedStoreCode) {
        finalStoreCode = selectedStoreCode;
      }
      locale.value = finalStoreCode;
      if (finalStoreCode == "ar") {
        document.documentElement.dir = "rtl";
        const currency = (countryCode == "AE") ? "درهم" : "جنية";
        storeX.commit("setCurrency", currency);
      } else {
        document.documentElement.dir = "ltr";
        const currency = (countryCode == "AE") ? "AED" : "EGP";
        storeX.commit("setCurrency", currency);
      }
      document.documentElement.lang = finalStoreCode;
      if (setStoreSession) {
        settingsApp.setStoreToSession(finalStoreCode);
      }
      storeX.commit("setStoreCode", finalStoreCode);
      settingsApp.setStates();
      setLocalStorageData({
        storeCode: finalStoreCode,
      });
    };

    const initFireBase = async (firebaseConfig, publicKey) => {
      const app = initializeApp(firebaseConfig);
      try {
        const messaging = await getMessaging(app);
        getToken(messaging, { vapidKey: publicKey })
          .then((currentToken) => {
            if (currentToken) {
              registerDevice(currentToken);
            } else {
              // No registration token available. Request permission to generate one.
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token.", err);
          });
        onMessage(messaging, (payload) => {
          if (payload.data["notification_item"]) {
            const notificationItem = JSON.parse(
              payload.data["notification_item"]
            );
            storeX.commit("addNotification", notificationItem);
            notificationApp.getNotifications(1);
            notificationApp.getNewNotificationsCount(false);
          }
          openToast(
            payload.notification.title + "<br />" + payload.notification.body,
            "medium",
            false,
            null,
            "top",
            "internal-notification",
            [
              {
                icon: close,
                role: "cancel",
              },
            ]
          );
        });
      } catch (error) {
        console.log(error);
      }
    };

    const initApp = () => {
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("getApp"), {
          app_code: "yallatager_pwa",
        })
        .then(async (response: any) => {
          if (!Object.prototype.hasOwnProperty.call(response, "error")) {
            const data = response.data;
            setLocalStorageData({
              appId: data.entity_id,
            });
            if (data.gtag == "1" && data.gtag_measurement_id) {
              setGtagOptions({
                config: { id: data.gtag_measurement_id },
              });
              gtagBootstrap();
            }
            if (data.firebase && data.firebase_config) {
              initFireBase(data.firebase_config, data.firebase_public_key);
            }
            registerDevice();
            try {
              const appVersion = await getAppVersion();
              if (compareVersion(appVersion, data.min_version) == -1) {
                setLocalStorageData({
                  versionForceUpdate:
                    Number(data.force_update) == 0 ? false : true,
                });
              }
            } catch (error) {
              console.log(error);
            }
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
      cartApp.getQuoteItems();
      compareApp.getProductsToCompare();
      wishlistApp.getWishlistItems();
      notificationApp.getNotifications(1);
      notificationApp.getNewNotificationsCount(false);
    };

    const createAffiliateSession = () => {
      const afc = route.query["afc"];
      httpApp.sendPostRequest(
        settingsApp.getEndpointUrl("createAffiliateSession"),
        {
          affiliate_code: afc,
        }
      );
      // .then((res: any) => {
      //   if (res.success) {
      //     if (res.redirect_url == "home") {
      //       router.push("/" + storeX.state.storeCode);
      //     } else {
      //       router.push(res.redirect_url);
      //     }
      //   }
      // });
    };

    const startApp = () => {
      settingsApp
        .initialize()
        .then(() => {
          setLanguageLayout();
          sessionApp
            .checkSession(true)
            .then((resp: any) => {
              storeX.commit("setCustomerData", resp);
            })
            .catch(() => {
              storeX.commit("setCustomerData", null);
            });
          initApp();

          createAffiliateSession();
          settingsApp
            .getVendorData()
            .then(() => {
              appInit.value = true;
            })
            .catch(() => {
              appInit.value = true;
            });
          console.log();
        })
        .catch((error) => {
          // if (!error) {
          //   startApp();
          // }
          console.log(error, "settings init error");
        });
    };

    provide("setLanguageLayout", setLanguageLayout);

    startApp();

    window.addEventListener("popstate", (event) => {
      if (event.state.current) {
        window.location.href = window.location.origin + event.state.current;
      } else {
        window.location.href = window.location.origin;
      }
    });

    return {
      appInit,
    };
  },
  components: {
    IonApp,
    IonRouterOutlet,
  },
});
