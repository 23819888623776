import { createApp } from "vue";
import App from "./App.vue";
import { store, key } from "./store";
import router from "./router";
import i18n from "@/i18n";
import VueGtag from "vue-gtag";
import "./registerServiceWorker";
//import "./gtm.js";

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Bootstrap */
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

/* Theme variables */
import "./theme/variables.css";
import "./theme/style-ar.css";
import "./theme/main.css";
import "./theme/responsive.css";
import "./theme/header.css";
import "./theme/account.css";
import "./theme/form.css";
import "./theme/uicons-regular-straight.css";

const app = createApp(App)
	.use(IonicVue)
	.use(store, key)
	.use(i18n)
	.use(router)
	.use(
		VueGtag,
		{
			appName: "PWA",
			bootstrap: false,
		},
		router
	);

router.isReady().then(() => {
	app.mount("#app");
});
