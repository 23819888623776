import { createRouter, createWebHistory } from "@ionic/vue-router";
//import { createRouter, createMemoryHistory } from "@ionic/vue-router";
import {
	RouteRecordRaw,
	RouteLocationNormalized,
	NavigationGuardNext,
} from "vue-router";
import i18n from "@/i18n";
import { useLocalStorage } from "@/libs/localStorage";
import sessionApp from "@/libs/session";
import { store } from "@/store";
import Home from "../views/Home.vue";
// import Category from "../views/Category.vue";
// import Product from "../views/Product.vue";
// import CMSPage from "../views/CMSPage.vue";
import NotFound from "../views/NotFound.vue";
//import DynamicRoute from "../views/DynamicRoute.vue";
//import RouterOutlet from "../views/RouterOutlet.vue";
//import AccountOutlet from "../views/account/Account.vue";
import httpApp from "@/libs/http";
import settingsApp from "@/libs//settings";

const accountGuard = (
	to: RouteLocationNormalized,
	from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	try {
		if (store.state.customerData) {
			next();
		} else {
			sessionApp
				.checkSession(true)
				.then((resp: any) => {
					if (resp.success) {
						store.commit("setCustomerData", resp);
						next();
					} else {
						next("/account/login");
					}
				})
				.catch(() => {
					next("/account/login");
				});
		}
	} catch (error) {
		sessionApp
			.checkSession(true)
			.then((resp: any) => {
				if (resp.success) {
					store.commit("setCustomerData", resp);
					next();
				} else {
					next("/account/login");
				}
			})
			.catch(() => {
				next("/account/login");
			});
	}
};

const authGuard = (
	to: RouteLocationNormalized,
	from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	try {
		if (store.state.customerData) {
			next("/account");
		} else {
			next();
		}
	} catch (error) {
		next();
	}
};

const vendorGuard = (
	to: RouteLocationNormalized,
	from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	try {
		if (store.state.sellerData) {
			if (
				store.state.sellerData.is_vendor &&
				store.state.sellerData.is_approved
			) {
				if (
					to.path == "/en/vendor/become-vendor" ||
					to.path == "/ar/vendor/become-vendor"
				) {
					next("/vendor/vendor-details");
				} else {
					next();
				}
			} else {
				if (
					to.path == "/en/vendor/become-vendor" ||
					to.path == "/ar/vendor/become-vendor"
				) {
					next();
				} else {
					next("/vendor/become-vendor");
				}
			}
		} else if (!store.state.customerData) {
			next("/account");
		} else {
			next("/vendor/become-vendor");
		}
	} catch (error) {
		next();
	}
};

const { setLocalStorageData, getLocalStorageData } = useLocalStorage();

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		component: Home,
		meta: {
			title: "Home Page",
		},
	},
	{
		path: "/:lang(en|ar)/index.html",
		redirect: "/ar",
	},
	{
		path: "/index.html",
		redirect: "/",
	},
	{
		path: "/:lang(en|ar)/",
		name: "mainHome",
		component: Home,
		meta: {
			title: "Home Page",
		},
	},
	{
		path: "/:lang(en|ar)",
		name: "mainHome",
		component: Home,
		meta: {
			title: "Home Page",
		},
	},
	{
		path: "/:lang(en|ar)/privacy_policy",
		name: "PrivacyPolicy",
		component: () => import("../views/PrivacyPolicy.vue"),
		meta: {
			title: "Privacy Policy",
		},
	},
	{
		path: "/:lang(en|ar)/account",
		name: "account",
		component: () => import("../views/account/Dashboard.vue"),
		beforeEnter: accountGuard,
		meta: {
			title: "Account Page",
		},
	},
	{
		path: "/:lang(en|ar)/account/addressbook",
		name: "addressbook",
		component: () => import("../views/account/AddressBook.vue"),
		beforeEnter: accountGuard,
		meta: {
			title: "Account Address",
		},
	},
	{
		path: "/:lang(en|ar)/account/addressbook/:address_id",
		name: "address",
		component: () => import("../views/account/AddressBook.vue"),
		beforeEnter: accountGuard,
		meta: {
			title: "Account Address",
		},
	},
	{
		path: "/:lang(en|ar)/account/mobile",
		name: "mobile",
		component: () => import("../views/account/Mobile.vue"),
		beforeEnter: accountGuard,
		meta: {
			title: "Account Mobile",
		},
	},
	{
		path: "/:lang(en|ar)/account/mywallet",
		name: "mywallet",
		component: () => import("../views/account/Wallet.vue"),
		beforeEnter: accountGuard,
		meta: {
			title: "Account Wallet",
		},
	},
	{
		path: "/:lang(en|ar)/account/orders",
		name: "orders",
		component: () => import("../views/account/Orders.vue"),
		beforeEnter: accountGuard,
		meta: {
			title: "Orders",
		},
	},
	{
		path: "/:lang(en|ar)/account/account-details",
		name: "account-details",
		component: () => import("../views/account/AccountDetails.vue"),
		beforeEnter: accountGuard,
		meta: {
			title: "Account Details",
		},
	},
	{
		path: "/:lang(en|ar)/account/order/:orderNo",
		name: "Order",
		component: () => import("../views/account/Order.vue"),
		//beforeEnter: accountGuard,
		meta: {
			title: "Order",
		},
	},
	{
		path: "/:lang(en|ar)/account/return-order/:orderNo",
		name: "order-return",
		component: () => import("../views/account/ReturnOrder.vue"),
		beforeEnter: accountGuard,
		meta: {
			title: "Order Return",
		},
	},
	{
		path: "/:lang(en|ar)/account/wishlist",
		name: "wishlist",
		component: () => import("../views/account/WishList.vue"),
		beforeEnter: accountGuard,
		meta: {
			title: "Wish List",
		},
	},
	{
		path: "/:lang(en|ar)/account/support-tickets",
		name: "support-tickets",
		component: () => import("../views/account/SupportTickets.vue"),
		beforeEnter: accountGuard,
		meta: {
			title: "Support Tickets",
		},
	},
	{
		path: "/:lang(en|ar)/account/support-ticket/:ticketNo",
		name: "support-ticket",
		component: () => import("../views/account/SupportTicket.vue"),
		beforeEnter: accountGuard,
		meta: {
			title: "Support Ticket",
		},
	},
	{
		path: "/:lang(en|ar)/account/affiliate-program",
		name: "affiliate-program",
		component: () => import("../views/account/AffiliateProgram.vue"),
		beforeEnter: accountGuard,
		meta: {
			title: "Support Tickets",
		},
	},
	{
		path: "/:lang(en|ar)/account/warranty-certificates",
		name: "warranty-certificates",
		component: () => import("../views/account/WarrantyCertificates.vue"),
		beforeEnter: accountGuard,
		meta: {
			title: "Warranty Certificates",
		},
	},
	{
		path: "/:lang(en|ar)/account/warranty-certificate/:certificateId",
		name: "warranty-certificate",
		component: () => import("../views/account/WarrantyCertificate.vue"),
		beforeEnter: accountGuard,
		meta: {
			title: "Warranty Certificate",
		},
	},
	{
		path: "/:lang(en|ar)/account/login",
		name: "login",
		component: () => import("../views/account/Login.vue"),
		beforeEnter: authGuard,
		meta: {
			title: "Account Login",
		},
	},
	{
		path: "/:lang(en|ar)/account/confirm",
		name: "confirm",
		component: () => import("../views/account/Confirm.vue"),
		beforeEnter: authGuard,
		meta: {
			title: "Account Login",
		},
	},
	{
		path: "/:lang(en|ar)/account/resetpassword",
		name: "resetpassword",
		component: () => import("../views/account/ResetPassword.vue"),
		beforeEnter: authGuard,
		meta: {
			title: "Reset Password",
		},
	},
	{
		path: "/:lang(en|ar)/account/signup",
		name: "signup",
		component: () => import("../views/account/Signup.vue"),
		beforeEnter: authGuard,
		meta: {
			title: "Account Signup",
		},
	},
	{
		path: "/:lang(en|ar)/account/socialconnect/:channel",
		name: "socialconnect",
		component: () => import("../views/account/Socialconnect.vue"),
		meta: {
			title: "Account facebook Auth Response",
		},
		props: true,
	},
	{
		path: "/:lang(en|ar)/vendor",
		name: "vendor",
		redirect: "/vendor/become-vendor",
	},
	{
		path: "/:lang(en|ar)/vendor/become-vendor",
		name: "become-vendor",
		component: () => import("../views/vendor/BecomeVendor.vue"),
		beforeEnter: vendorGuard,
		meta: {
			title: "Become Vendor",
		},
	},
	{
		path: "/:lang(en|ar)/vendor/vendor-details",
		name: "vendor-details",
		component: () => import("../views/vendor/VendorDetails.vue"),
		beforeEnter: vendorGuard,
		meta: {
			title: "Vendor Details",
		},
	},
	{
		path: "/:lang(en|ar)/vendor/add-products",
		name: "add-products",
		component: () => import("../views/vendor/AddProducts.vue"),
		beforeEnter: vendorGuard,
		meta: {
			title: "Add Products",
		},
	},
	{
		path: "/:lang(en|ar)/vendor/products-list",
		name: "products-list",
		component: () => import("../views/vendor/ProductList.vue"),
		beforeEnter: vendorGuard,
		meta: {
			title: "Products List",
		},
	},
	{
		path: "/:lang(en|ar)/vendor/edit-product/:productId",
		name: "edit-product",
		component: () => import("../views/vendor/EditProduct.vue"),
		beforeEnter: vendorGuard,
		meta: {
			title: "Edit Product",
		},
	},
	{
		path: "/:lang(en|ar)/vendor/pending-orders",
		name: "pending-orders",
		component: () => import("../views/vendor/PendingOrders.vue"),
		beforeEnter: vendorGuard,
		meta: {
			title: "Pending Orders",
		},
	},
	{
		path: "/:lang(en|ar)/vendor/past-orders",
		name: "past-orders",
		component: () => import("../views/vendor/PastOrders.vue"),
		beforeEnter: vendorGuard,
		meta: {
			title: "Past Orders",
		},
	},
	{
		path: "/:lang(en|ar)/vendor/stock-report",
		name: "stock-report",
		component: () => import("../views/vendor/ReportStock.vue"),
		beforeEnter: vendorGuard,
		meta: {
			title: "Stock Report",
		},
	},
	{
		path: "/:lang(en|ar)/vendor/order/:orderId",
		name: "vendor-order",
		component: () => import("../views/vendor/Order.vue"),
		beforeEnter: vendorGuard,
		meta: {
			title: "Order",
		},
	},
	{
		path: "/:lang(en|ar)/vendor/commissions",
		name: "commissions",
		component: () => import("../views/vendor/Commissions.vue"),
		beforeEnter: vendorGuard,
		meta: {
			title: "Commissions",
		},
	},
	{
		path: "/:lang(en|ar)/vendor/cash-back",
		name: "cash-back",
		component: () => import("../views/vendor/CashBack.vue"),
		beforeEnter: vendorGuard,
		meta: {
			title: "Cash Back",
		},
	},
	{
		path: "/:lang(en|ar)/cart",
		name: "cart",
		component: () => import("../views/Cart.vue"),
		meta: {
			title: "Cart",
		},
	},
	{
		path: "/:lang(en|ar)/compare",
		name: "compare",
		component: () => import("../views/Compare.vue"),
		meta: {
			title: "Compare",
		},
	},
	{
		path: "/:lang(en|ar)/contact_us.html",
		name: "contactUs",
		component: () => import("../views/ContactUs.vue"),
		meta: {
			title: "contactUs",
		},
	},
	{
		path: "/:lang(en|ar)/our_sellers.html",
		name: "ourSellers",
		component: () => import("../views/OurSellers.vue"),
		meta: {
			title: "ourSellers",
		},
	},
	{
		path: "/:lang(en|ar)/checkout",
		name: "checkout",
		component: () => import("../views/Checkout.vue"),
		beforeEnter: accountGuard,
		meta: {
			title: "Checkout",
		},
	},
	{
		path: "/:lang(en|ar)/pay/processing/:payment_method/:order_no",
		name: "payprocessing",
		component: () => import("../views/Pay.vue"),
		beforeEnter: accountGuard,
		meta: {
			title: "Pay Processing",
		},
		props: true,
	},
	{
		path: "/:lang(en|ar)/pay/confirming/:type/:order_no/:payment_method",
		name: "payconfirming",
		component: () => import("../views/PayConfirming.vue"),
		//beforeEnter: accountGuard,
		meta: {
			title: "Pay Confirming",
		},
		props: true,
	},
	{
		path: "/:lang(en|ar)/checkout/success/:order_no",
		name: "checkoutsuccess",
		component: () => import("../views/CheckoutSuccess.vue"),
		beforeEnter: accountGuard,
		meta: {
			title: "Checkout Success",
		},
	},
	{
		path: "/:lang(en|ar)/vendor/:vendor_code",
		name: "vendorPage",
		component: () => import("../views/Vendor.vue"),
		meta: {
			title: "Vendor Page",
		},
	},
	{
		path: "/:lang(en|ar)/search/:query",
		name: "searchPage",
		component: () => import("../views/SearchPage.vue"),
		meta: {
			title: "Search Results",
		},
	},
	{
		path: "/:lang(en|ar)/:catchAll(.*)",
		name: "invalid",
		components: {
			default: NotFound,
		},
	},
	// {
	// 	path: "/:lang(en|ar)/",
	// 	component: RouterOutlet,
	// 	children: [],
	// },
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach(async (to, from, next) => {
	// Set lang based on url
	i18n.global.locale.value =
		to.params.lang ||
		getLocalStorageData("storeCode") ||
		i18n.global.locale.value;

	if (from.params.lang && to.params.lang) {
		if (from.params.lang != to.params.lang) {
			setLocalStorageData({ storeCode: i18n.global.locale.value });
		}
	}

	if (to.fullPath == "/en/") {
		next("/en");
	}
	if (to.fullPath == "/ar/") {
		next("/ar");
	}

	store.commit("setStoreCode", i18n.global.locale.value);

	setLocalStorageData({ storeCode: i18n.global.locale.value });

	// Redirect to locale url based on from
	if (from.params.lang && !to.params.lang) {
		next("/" + from.params.lang + to.fullPath);
		return;
	} else if (!to.params.lang) {
		if (
			!to.fullPath.startsWith("/en/") &&
			!to.fullPath.startsWith("/ar/")
		) {
			if (to.fullPath == "/") {
				next("/" + i18n.global.locale.value); // if you want to force language
				return;
				//next();
			} else {
				next("/" + i18n.global.locale.value + to.fullPath);
				return;
			}
		}
	}

	if (to.params["catchAll"]) {
		const langCode = to.params["lang"];
		const request_path = to.params["catchAll"];
		let finalPath = "/:lang(en|ar)/" + request_path;
		let navPath = "/" + store.state.storeCode + "/" + request_path;
		let pathName = langCode + "-" + request_path;

		let fromStore = "";
		const searchParamsArr = to.fullPath.split("?");
		if (searchParamsArr[1]) {
			const searchParams = "?" + searchParamsArr[1];
			const urlParams = new URLSearchParams(searchParams);
			fromStore = urlParams.get("from_store");
			if (fromStore) navPath += "?from_store=" + fromStore;
		}
		try {
			const paramsParse = {
				store_view: store.state.storeCode,
				from_store: fromStore,
				request_path: request_path,
			} as Record<string, string>;
			const paramsString = new URLSearchParams(paramsParse).toString();
			const response = (await httpApp.sendGetRequest(
				settingsApp.getEndpointUrl("parseUrlKey") + "?" + paramsString
			)) as any;
			// if (Object.prototype.hasOwnProperty.call(response, "token-valid")) {
			// 	await settingsApp.setPwaToken();
			// 	response = (await httpApp.sendPostRequest(
			// 		settingsApp.getEndpointUrl("parseUrlKey"),
			// 		{
			// 			request_path: request_path,
			// 		}
			// 	)) as any;
			// }
			if (response.success) {
				if (response.data["new_path"]) {
					finalPath = "/:lang(en|ar)/" + response.data["new_path"];
					navPath =
						"/" +
						store.state.storeCode +
						"/" +
						response.data["new_path"];
					pathName = langCode + "-" + response.data["new_path"];
				}
				if (searchParamsArr[1]) {
					navPath += "?" + searchParamsArr[1];
				}
				let newRoute;
				if (response.data.object_type == "product") {
					newRoute = {
						path: finalPath,
						name: pathName,
						component: () => import("../views/Product.vue"),
						props: {
							product_id: response.data.object_id,
							lang: langCode,
						},
						params: {
							lang: langCode,
						},
					} as RouteRecordRaw;
				} else if (response.data.object_type == "category") {
					newRoute = {
						path: finalPath,
						name: pathName,
						component: () => import("../views/Category.vue"),
						props: {
							category_id: response.data.object_id,
							lang: langCode,
						},
						params: {
							lang: langCode,
						},
					} as RouteRecordRaw;
				} else if (response.data.object_type == "cms_page") {
					newRoute = {
						path: finalPath,
						name: pathName,
						component: () => import("../views/CMSPage.vue"),
						props: {
							pageId: response.data.object_id,
							pageIdentifier: request_path,
							lang: langCode,
						},
						params: {
							lang: langCode,
						},
					} as RouteRecordRaw;
				}
				router.addRoute(newRoute);
				next(navPath);
				return;
			} else {
				const newRoute = {
					path: finalPath,
					name: langCode + "-" + request_path,
					component: NotFound,
				} as RouteRecordRaw;
				router.addRoute(newRoute);
				next(navPath);
				return;
			}
		} catch (error) {
			next();
			return;
		}
	} else {
		next();
		return;
	}
});

router.onError((error) => {
	console.log(error, "router error");
});
export default router;
