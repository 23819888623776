
import Menu from "./Menu.vue";
import MiniCart from "../components/MiniCart.vue";
import Notifications from "../components/Notifications.vue";
import CategoriesMegaMenu from "../components/CategoriesMegaMenu.vue";

import {
	defineComponent,
	ref,
	onMounted,
	inject,
	reactive,
	watch,
	computed,
} from "vue";
import { IonSearchbar, IonSpinner, IonIcon } from "@ionic/vue";
import { logoAndroid, logoApple } from "ionicons/icons";
import { useRouter, useRoute, RouteRecordRaw } from "vue-router";
import { useLocalStorage } from "@/libs/localStorage";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import settingsApp from "@/libs/settings";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
import httpApp from "@/libs/http";

import { event as gtagEvent } from "vue-gtag";

export default defineComponent({
	name: "MainHeader",
	emits: ["emitSearchQuery"],
	props: {
		searchStarted: Boolean,
		searchQuery: String,
	},
	setup(props, { emit }) {
		const { t } = useI18n();
		const router = useRouter();
		const route = useRoute();
		const storeX = useStore();
		const { getLocalStorageData } = useLocalStorage();
		const { compareDate, formatNumberLocale } = useGlobalFunctions();

		const icons = ref({
			logoAndroid: logoAndroid,
			logoApple: logoApple,
		});
		const socialMedia = ref();

		const storePhone = getLocalStorageData("storeInformationPhone");
		const taxNumber = getLocalStorageData("merchantVatNumber");

		const openCategory = ref(false);
		const openMobileMenu = ref(false);
		const openLangDropDown = ref(false);
		const categories = ref([]);
		const setLanguageLayout = inject("setLanguageLayout") as any;

		const domainLangUrl = window.location.origin;
		const isAE = domainLangUrl.includes("ae.yallatager");

		const getCategories = () => {
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("categoryByLevel"), {
					store_view: getLocalStorageData("storeCode"),
					level: 2,
				})
				.then(async (response: any) => {
					if (response.items) {
						categories.value = response.items;
						storeX.commit("setMainCategories", response.items);
					}
				})
				.catch((error) => {
					console.log(error, "error");
				});
		};
		function setCategories() {
			const storedCategory = storeX.state.mainCategories;
			if (storedCategory.length) {
				categories.value = storedCategory;
			} else {
				getCategories();
			}
		}

		const findYotActive = ref(getLocalStorageData("findYotActive"));
		const findYotShowProducts = ref(getLocalStorageData("findYotShowProducts"));

		const mainSearchbar = ref();
		const searchAborter = ref();
		const search = reactive({
			started: false,
			products: [],
			category_id: 0,
			query: "",
			suggest: [],
		});

		const showSearchRefBox = computed(() => {
			if (
				!search.started &&
				search.query.length &&
				(search.products.length > 0 || search.suggest.length > 0) &&
				route.name != "searchPage"
			) {
				return true;
			}
			return false;
		});

		const getSuggestText = (item) => {
			let text = item.text;
			if (Number(item.freq) > 1) {
				text += " (" + t("searchTermUsed", { freq: item["freq"] }) + ")";
			}
			return text;
		};

		const searchProducts = () => {
			if (!findYotShowProducts.value) {
				return;
			}
			let aborted = false;
			if (searchAborter.value) {
				searchAborter.value.abort();
				aborted = true;
			}
			if (search.query.length > 0) {
				searchAborter.value = new AbortController();
				const signal = searchAborter.value.signal;
				let url = settingsApp.getEndpointUrl("findyotSearch");
				if (!findYotActive.value) {
					url = settingsApp.getEndpointUrl("catalogSearch");
				}
				search.started = true;
				httpApp
					.sendPostRequest(
						url,
						{
							store_view: getLocalStorageData("storeCode"),
							category_id: search.category_id,
							q: search.query,
							query: search.query,
							limit: 10,
							page: 1,
						},
						true,
						signal
					)
					.then(async (response: any) => {
						gtagEvent("search", { search_term: search.query });
						if (response.success) {
							if (findYotActive.value) {
								search.products = response.data.products;
							} else {
								search.products = response.items;
							}
							gtagEvent("view_search_results", {
								search_term: search.query,
								items_count: search.products.length,
							});
						} else {
							search.products = [];
							if (Object.prototype.hasOwnProperty.call(response, "suggest")) {
								if (response.suggest.length > 0) {
									search.suggest = response.suggest;
								}
							}
						}
						search.started = false;
					})
					.catch((error) => {
						console.log(error, "error");
						if (!aborted) {
							search.started = false;
						}
						search.products = [];
					});
			} else {
				search.started = false;
			}
		};
		const searchSuggestions = () => {
			if (!findYotActive.value) {
				return;
			}
			let aborted = false;
			if (searchAborter.value) {
				searchAborter.value.abort();
				aborted = true;
			}
			if (search.query.length > 0) {
				searchAborter.value = new AbortController();
				const signal = searchAborter.value.signal;
				const url = settingsApp.getEndpointUrl("findyotGetQuerySuggestions");
				search.started = true;
				httpApp
					.sendPostRequest(
						url,
						{
							store_view: getLocalStorageData("storeCode"),
							query: search.query,
						},
						true,
						signal
					)
					.then(async (response: any) => {
						gtagEvent("search", { search_term: search.query });
						search.suggest = [];
						if (response.success) {
							if (
								Object.prototype.hasOwnProperty.call(
									response,
									"name_suggestion"
								)
							) {
								if (Object.keys(response.name_suggestion).length > 0) {
									Object.keys(response.name_suggestion).forEach((key) => {
										response.name_suggestion[key].forEach((item) => {
											search.suggest.push(item);
										});
									});
									//search.suggest = response['name_suggestion'];
								} else {
									search.suggest.push({
										text: search.query,
										freq: null,
										score: null,
									});
								}
							}
						}
						search.started = false;
					})
					.catch((error) => {
						console.log(error, "error");
						if (!aborted) {
							search.started = false;
						}
						search.products = [];
					});
			} else {
				search.started = false;
			}
		};
		const setNewSearch = (text) => {
			search.query = text;
			searchSuggestions();
			if (route.name != "searchPage") {
				router.push({
					name: "searchPage",
					params: {
						query: search.query,
					},
				});
			} else {
				searchProducts();
			}
		};
		const setSearchQuery = (event: any, type: any) => {
			search.query = event.target.value;
			emit("emitSearchQuery", {
				query: search.query,
				type: type,
				event: event,
			});
			searchSuggestions();
			if (route.name != "searchPage") {
				searchProducts();
				if (type == "search") {
					router.push({
						name: "searchPage",
						params: {
							query: search.query,
						},
					});
					mainSearchbar.value.$el
						.getInputElement()
						.then((elm: HTMLInputElement) => {
							elm.blur();
						});
				}
			}
		};

		const getImageUrl = (category: any) => {
			if (category.image) {
				return (
					getLocalStorageData("websiteUrl") +
					"media/catalog/category/" +
					category.image
				);
			} else {
				return "assets/images/placeholder.png";
			}
		};

		const getProductUrl = (product) => {
			let url = "/" + storeX.state.storeCode;
			if (product.url_path_category) {
				url += "/" + product.url_path_category;
			} else {
				url += "/" + product.url_path;
			}
			return url;
		};

		const getProductImageUrl = (product) => {
			if (product.image == "no_selection") {
				return "assets/images/placeholder.png";
			} else if (product.image) {
				return (
					getLocalStorageData("websiteUrl") +
					"media/catalog/product" +
					product.image
				);
			} else if (product.thumbnail) {
				return (
					getLocalStorageData("websiteUrl") +
					"media/catalog/product" +
					product.thumbnail
				);
			} else if (product.small_image) {
				return (
					getLocalStorageData("websiteUrl") +
					"media/catalog/product" +
					product.small_image
				);
			} else {
				return "assets/images/placeholder.png";
			}
		};

		const goToProduct = (product, event) => {
			event.preventDefault();
			const newRoute = {
				path: "/:lang(en|ar)/" + product.url_key,
				name: storeX.state.storeCode + "-" + product.url_key,
				component: () => import("../views/Product.vue"),
				props: {
					product_id: product.entity_id,
					lang: storeX.state.storeCode,
				},
			} as RouteRecordRaw;
			router.addRoute(newRoute);
			router.push({
				name: storeX.state.storeCode + "-" + product.url_key,
			});
		};

		const getProductRating = (product) => {
			const rating = product.review_summary.rating_summary;
			if (rating > 0) {
				return rating + "%";
			}
			return 0 + "%";
		};

		const getOffer = (product) => {
			if (product.special_price) {
				const now = new Date();
				const fromDate = product.special_from_date;
				const toDate = product.special_to_date;
				if (
					(!fromDate && !toDate) ||
					(!fromDate && compareDate(now, toDate) == -1) ||
					(!toDate && compareDate(now, fromDate) == 1) ||
					(fromDate &&
						toDate &&
						compareDate(now, toDate) == -1 &&
						compareDate(now, fromDate) == 1)
				) {
					let offer =
						(Number(product.special_price) / Number(product.price)) * 100;
					offer = Math.ceil(offer);

					return 100 - offer;
				}
			} else {
				return false;
			}
		};
		const getOfferTxt = (product) => {
			const offer = getOffer(product);
			const txt = offer + "%";
			return txt;
		};
		const getPrice = (product) => {
			const offer = getOffer(product);
			let price = Number(product.final_price) + 0;
			if (offer) {
				price = Number(product.special_price) + 0;
			}

			return formatNumberLocale(price);
		};
		const getOldPrice = (product) => {
			const price = Number(product.price) + 0;
			return formatNumberLocale(price);
		};

		const selectStore = (storeCode) => {
			settingsApp.setStoreToSession(storeCode).then(() => {
				const pathArr = route.path.split("/");
				pathArr[1] = storeCode;
				let newPath = pathArr.join("/");
				const urlParams = new URLSearchParams(window.location.search);
				const fromStore = urlParams.get("from_store");
				if (!fromStore) {
					newPath += "?from_store=" + storeX.state.storeCode;
				}
				router.push(newPath);
				setLanguageLayout(storeCode, false);
			});
		};

		const getToAccountUrl = () => {
			let url = "/" + storeX.state.storeCode;
			if (storeX.state.customerData) {
				url += "/account";
			} else {
				url += "/account/login";
			}
			return url;
		};

		const getUrl = (path) => {
			const url = "/" + storeX.state.storeCode + "/" + path;
			return url;
		};

		const nav = (path, event) => {
			event.preventDefault();
			router.push(path);
		};

		const getSocialMedia = () => {
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("cmsBlock"), {
					identifier: "social_media",
				})
				.then(async (response: any) => {
					if (response.success) {
						socialMedia.value = response.block_data.content_html;
					}
				})
				.catch((error) => {
					console.log(error, "error");
				});
		};

		const mobileAppLinks = ref("");
		const getMobileAppLinks = () => {
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("cmsSimpleBlocks"), {
					identifiers: "customer_app_links",
				})
				.then(async (response: any) => {
					if (response["customer_app_links"].success) {
						mobileAppLinks.value =
							response["customer_app_links"].block_data.content;
					}
				})
				.catch((error) => {
					console.log(error, "error");
				});
		};

		const menuCodes = ref(["main_menu"]);
		const gettingHeaderMenus = () => {
			const finalMenuCodes = menuCodes.value.map((code) => {
				const foterMenu = code + "_" + storeX.state.storeCode;
				return foterMenu;
			});
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("getMenus"), {
					store_view: getLocalStorageData("storeCode"),
					menu_codes: finalMenuCodes,
				})
				.then(async (response: any) => {
					Object.keys(response).forEach((code) => {
						const responseItem = response[code];
						if (responseItem.success) {
							storeX.commit("addMenu", responseItem);
						}
					});
				})
				.catch((error) => {
					console.log(error, "error");
				});
		};

		watch(
			() => storeX.state.storeCode,
			() => {
				searchProducts();
				getCategories();
				getSocialMedia();
				gettingHeaderMenus();
				getMobileAppLinks();
			}
		);

		watch(
			() => search.query,
			(val) => {
				if (val.length == 0) {
					search.products = [];
				}
			}
		);

		watch(
			() => props.searchQuery,
			(val) => {
				search.query = val;
			}
		);

		onMounted(() => {
			setCategories();
			getSocialMedia();
			getMobileAppLinks();
			gettingHeaderMenus();
			window.addEventListener("click", function(e: any) {
				if (
					document
						.getElementById("categoriesMegaMenuTrig")
						.contains(e.target) ||
					document.getElementById("categoriesMegaMenuTrig2").contains(e.target)
				) {
					return false;
				}
				if (
					!document.getElementById("categoriesMegaMenuWrap").contains(e.target)
				) {
					if (openCategory.value) {
						openCategory.value = false;
					}
				}
			});
		});

		watch(
			() => route.name,
			() => {
				if (route.name == "searchPage") {
					search.query = route.params.query as string;
				}
			},
			{ immediate: true }
		);

		return {
			t,
			props,
			storeX,
			routeName: route.name,
			isAE,
			openCategory,
			openMobileMenu,
			openLangDropDown,
			categories,
			search,
			setSearchQuery,
			setNewSearch,
			searchProducts,
			findYotActive,
			findYotShowProducts,
			showSearchRefBox,
			getSuggestText,
			mainSearchbar,
			goToProduct,
			getProductUrl,
			getProductImageUrl,
			getProductRating,
			getOfferTxt,
			getOffer,
			getPrice,
			getOldPrice,
			storePhone,
			selectStore,
			getImageUrl,
			nav,
			getToAccountUrl,
			getUrl,
			socialMedia,
			mobileAppLinks,
			taxNumber,
			icons,
		};
	},
	components: {
		Menu,
		IonSearchbar,
		IonSpinner,
		IonIcon,
		MiniCart,
		Notifications,
		CategoriesMegaMenu,
	},
});
