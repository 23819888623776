import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "main homedemo3 homedemo2" }
const _hoisted_2 = { class: "home-slider position-relative mb-30" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "home-slide-cover mt-30" }
const _hoisted_5 = { class: "main" }
const _hoisted_6 = { class: "newsletter wow fadeIn animated mb-15" }
const _hoisted_7 = { class: "container" }
const _hoisted_8 = { class: "sectionWrap" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-lg-6 d-lg-flex" }
const _hoisted_11 = { class: "position-relative newsletter-inner" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "col-lg-6 d-lg-flex ion-float-end" }
const _hoisted_14 = { class: "position-relative newsletter-inner" }
const _hoisted_15 = { class: "newsletter-content" }
const _hoisted_16 = ["placeholder"]
const _hoisted_17 = {
  class: "featured wow fadeIn animated section-padding animated animated",
  style: {"visibility":"visible"}
}
const _hoisted_18 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_Slider = _resolveComponent("Slider")!
  const _component_CategoryProductSlider = _resolveComponent("CategoryProductSlider")!
  const _component_MainFooter = _resolveComponent("MainFooter")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_MainHeader),
          _createElementVNode("main", _hoisted_1, [
            _createElementVNode("section", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_Slider, {
                    code: 'home_1_' + _ctx.storeX.state.storeCode,
                    page: "home",
                    height: 500
                  }, null, 8, ["code"])
                ])
              ])
            ]),
            (_ctx.homeProductsSliders.length)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.homeProductsSliders, (id, index) => {
                  return (_openBlock(), _createElementBlock("section", {
                    class: "section-padding pb-5",
                    key: index
                  }, [
                    _createVNode(_component_CategoryProductSlider, { category_id: id }, null, 8, ["category_id"])
                  ]))
                }), 128))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("footer", _hoisted_5, [
            _createElementVNode("section", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", {
                          class: "newsletter-content",
                          innerHTML: _ctx.getHomeBlocksHtml('newsLetter_content')
                        }, null, 8, _hoisted_12)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("form", {
                            class: "form-subcriber d-flex",
                            onSubmit: _cache[2] || (_cache[2] = ($event: any) => (_ctx.newsletterSubscribeAcction($event)))
                          }, [
                            _withDirectives(_createElementVNode("input", {
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newsletter.email) = $event)),
                              type: "text",
                              autocomplete: "email",
                              placeholder: _ctx.t('signUp.email'),
                              class: _normalizeClass({
														'is-invalid': _ctx.vNewsletter.email.$errors.length,
													})
                            }, null, 10, _hoisted_16), [
                              [_vModelText, _ctx.newsletter.email]
                            ]),
                            _createElementVNode("button", {
                              class: "btn",
                              type: "submit",
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.newsletterSubscribeAcction($event)))
                            }, _toDisplayString(_ctx.t("account.subscribe")), 1)
                          ], 32)
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("section", _hoisted_17, [
              _createElementVNode("div", {
                class: "container",
                innerHTML: _ctx.getHomeBlocksHtml('free_delivery')
              }, null, 8, _hoisted_18)
            ]),
            _createVNode(_component_MainFooter)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}