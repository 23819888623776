
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import settingsApp from "@/libs/settings";
import httpApp from "@/libs/http";
import { useRouter } from "vue-router";
import { useStore } from "@/store";

import { IonSlides, IonSlide, IonImg } from "@ionic/vue";

export default defineComponent({
  name: "Slider",
  props: {
    code: String,
    page: String,
    height: Number,
  },
  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const storeX = useStore();

    const slideOpts = ref({
      initialSlide: 0,
      effect: "slide",
      speed: 400,
      autoplay: {
        delay: 500,
      },
      height: props.height,
      slidesPerView: 1,
      spaceBetween: 10,
      paginationP: false,
      navigationP: false,
      scrollbarP: false,
      loop: false,
      dir: "ltr",
      direction: "horizontal",
    });

    let page = "";
    if (props.page == "home") {
      page = "home";
    } else {
      page = "general";
    }
    const slider = ref();
    const slides = ref([]);

    function setSliderOptions(settings: any) {
      const allowedAnimtaions = ["slide", "fade", "cube", "coverflow", "flip"];
      if (
        settings.animation &&
        allowedAnimtaions.indexOf(settings.animation) != -1
      ) {
        slideOpts.value.effect = settings.animation;
      }
      if (Number(settings.slides_per_view) > 1) {
        slideOpts.value.slidesPerView = Number(settings.slides_per_view);
      }
      if (Number(settings.show_pagination) == 0) {
        slideOpts.value.paginationP = false;
      } else {
        slideOpts.value.paginationP = true;
      }
      if (Number(settings.show_navigation) == 0) {
        slideOpts.value.navigationP = false;
      } else {
        slideOpts.value.navigationP = true;
      }
      if (Number(settings.show_scrollbar) == 0) {
        slideOpts.value.scrollbarP = false;
      } else {
        slideOpts.value.scrollbarP = true;
      }
      if (Number(settings.loop) == 0) {
        slideOpts.value.loop = false;
      } else {
        slideOpts.value.loop = true;
      }
      if (Number(settings.autoplay) == 1 && Number(settings.autoplay_delay)) {
        slideOpts.value.autoplay = {
          delay: Number(settings.autoplay_delay),
        };
      }
      if (Number(settings.speed)) {
        slideOpts.value.speed = Number(settings.speed);
      }
      if (storeX.state.storeCode == "ar") {
        slideOpts.value.dir = "rtl";
      } else {
        slideOpts.value.dir = "ltr";
      }
      if (settings.direction) {
        slideOpts.value.direction = settings.direction;
      }
    }

    const getSlider = () => {
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("getSlider"), {
          slider_code: props.code,
        })
        .then(async (response: any) => {
          if (response.success) {
            setSliderOptions(response.data.slider_setting);
            slider.value = response.data;
            if (response.data.slides) {
              slides.value = response.data.slides;
            }

            storeX.commit("addSlider", response);
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    };

    function setSlider() {
      if (storeX.state.sliders[page]) {
        Object.keys(storeX.state.sliders[page]).forEach((key, index) => {
          if (key == props.code) {
            setSliderOptions(storeX.state.sliders[page][key].slider_setting);
            slider.value = storeX.state.sliders[page][key];
          }
          if (
            Object.keys(storeX.state.sliders[page]).length == index + 1 &&
            !slider.value
          ) {
            getSlider();
          }
        });
      } else {
        getSlider();
      }
    }
    const imageClicked = (event: any, slide: any) => {
      event.preventDefault();
      if (
        slide.url_type == "category" ||
        slide.url_type == "product" ||
        slider.value.type == "products_slides" ||
        slider.value.type == "categories_slides"
      ) {
        if (slide["url_path"]) {
          router.push("/" + storeX.state.storeCode + "/" + slide.url_path);
        }
      } else if (slide.url_type == "external") {
        window.open(slide.url_data, "_blank");
      }
    };

    const slideHasLink = (slide) => {
      if (
        slide.url_type == "category" ||
        slide.url_type == "product" ||
        slide.url_type == "external"
      ) {
        if (slide["url_path"]) {
          return true;
        }
      }
      return false;
    };

    const getSlideImage = function (slide: any) {
      if (Object.prototype.hasOwnProperty.call(slide, "img_url")) {
        return slide.img_url;
      } else if (slider.value.type == "products_slides") {
        if (slide.image) {
          return (
            settingsApp.getEndpointUrl("websiteUrl") +
            "media/catalog/product" +
            slide.image
          );
        }
      } else {
        return null;
      }
    };
    onMounted(() => {
      setSlider();
    });
    return {
      t,
      slides,
      slideOpts,
      imageClicked,
      getSlideImage,
      slideHasLink,
    };
  },
  components: {
    IonSlides,
    IonSlide,
    IonImg,
  },
});
