import { useLocalStorage } from "./localStorage";
const { getLocalStorageData } = useLocalStorage();
//import { store as storeX } from "@/store";
//import settingsApp from "@/libs/settings";
//const domainLangUrl = window.location.origin;
//const develop = domainLangUrl.includes("localhost");
const httpApp = {
	getHeaders: (isJson = true) => {
		const headers = {
			APIKEY: getLocalStorageData("apiKey"),
			APICODE: getLocalStorageData("apiCode"),
		};
		if (isJson) {
			headers["Content-Type"] = "application/json";
		}

		if (document.cookie) {
			const currentCookies = document.cookie.split(";");
			headers["pwacookie"] = currentCookies;
		}
		return headers;
	},
	sendPostRequest: async (
		url: string,
		sendData: any,
		stringfy = true,
		signal = null
	) => {
		const headers = httpApp.getHeaders();
		if (!signal) {
			signal = new AbortController().signal;
		}
		const response = await fetch(url, {
			headers: headers,
			credentials: "include",
			method: "POST",
			body: stringfy ? JSON.stringify(sendData) : sendData,
			signal,
		});
		// check for 503 error and retry
		if (response.status === 503) {
			await new Promise((resolve) => setTimeout(resolve, 200)); // wait before retrying
			return httpApp.sendPostRequest(url, sendData, stringfy, signal);
		}
		const returnData = await response.json();
		if (Object.prototype.hasOwnProperty.call(returnData, "pwaCookie")) {
			const pwaCookie = returnData.pwaCookie;
			delete returnData.pwaCookie;
			pwaCookie.forEach((cookie) => {
				const cookieArr = cookie.split(";");
				const name = cookieArr[0].split("=")[0];
				const value = cookieArr[0].split("=")[1];
				const exDate = cookieArr[1].split("=")[1];
				const generatedCookie =
					name + "=" + value + ";expires=" + exDate + ";path=/";
				document.cookie = generatedCookie;
			});
		}

		return new Promise((resolve, reject) => {
			if (!response.ok) {
				const message = response.status;
				reject(message);
			} else {
				resolve(returnData);
			}
		});
	},

	sendPostFormData: async (url: string, formData: any) => {
		const headers = httpApp.getHeaders(false);
		const response = await fetch(url, {
			headers,
			credentials: "include",
			method: "POST",
			body: formData,
		});
		// check for 503 error and retry
		if (response.status === 503) {
			await new Promise((resolve) => setTimeout(resolve, 200)); // wait before retrying
			return httpApp.sendPostFormData(url, formData);
		}
		const returnData = await response.json();

		return new Promise((resolve, reject) => {
			if (!response.ok) {
				const message = response.status;
				reject(message);
			} else {
				resolve(returnData);
			}
		});
	},

	sendGetRequest: async (url: string) => {
		const headers = httpApp.getHeaders();
		const response = await fetch(url, {
			headers,
			credentials: "include",
			method: "GET",
		});
		// check for 503 error and retry
		if (response.status === 503) {
			await new Promise((resolve) => setTimeout(resolve, 200)); // wait before retrying
			return httpApp.sendGetRequest(url);
		}
		const returnData = await response.json();

		return new Promise((resolve, reject) => {
			if (!response.ok) {
				const message = response.status;
				reject(message);
			} else {
				resolve(returnData);
			}
		});
	},

	sendGetRequestBlob: async (url: string) => {
		const headers = httpApp.getHeaders();
		const response = await fetch(url, {
			method: "GET",
			headers,
			credentials: "include",
		});

		// check for 503 error and retry
		if (response.status === 503) {
			await new Promise((resolve) => setTimeout(resolve, 200)); // wait before retrying
			return httpApp.sendGetRequestBlob(url);
		}

		const returnData = await response.blob();

		return new Promise((resolve, reject) => {
			if (!response.ok) {
				const message = response.status;
				reject(message);
			} else {
				resolve(returnData);
			}
		});
	},
};

export default httpApp;
