
import { defineComponent, ref, onMounted, watch, computed } from "vue";
import {
	IonImg,
	IonSlides,
	IonSlide,
	IonIcon,
	IonSkeletonText,
} from "@ionic/vue";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";
import { useLocalStorage } from "@/libs/localStorage";
//import { useGlobalFunctions } from "@/libs/globalFunctions";
import settingsApp from "@/libs/settings";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
import httpApp from "@/libs/http";
import ProductItem from "../components/ProductItem.vue";
import Banner from "../components/Banner.vue";

export default defineComponent({
	name: "CategoryProductSlider",
	props: {
		category_id: String,
	},
	setup(props) {
		const { t } = useI18n();
		const storeX = useStore();
		const { getLocalStorageData } = useLocalStorage();
		//const { compareDate, formatNumberLocale } = useGlobalFunctions();

		const icons = ref({
			chevronBackOutline,
			chevronForwardOutline,
		});

		const subCatSlider = ref(null);
		const slideSubCatOpts = ref({
			initialSlide: 0,
			effect: "slide",
			speed: 400,
			autoplay: false,
			slidesPerView: 2,
			spaceBetween: 10,
			navigation: true,
			scrollbar: true,
			loop: false,
			dir: "ltr",
			direction: "horizontal",
			breakpoints: {
				320: {
					slidesPerView: 2,
				},
				480: {
					slidesPerView: 2,
				},
				640: {
					slidesPerView: 6,
				},
			},
		});

		const subCatNext = () => {
			subCatSlider.value.$el.slideNext();
		};

		const subCatPrev = () => {
			subCatSlider.value.$el.slidePrev();
		};

		const productsSlider = ref(null);
		const slideProductOpts = ref({
			initialSlide: 0,
			effect: "slide",
			speed: 400,
			autoplay: false,
			slidesPerView: 2,
			spaceBetween: 10,
			paginationP: false,
			navigation: true,
			scrollbar: true,
			loop: false,
			dir: "ltr",
			direction: "horizontal",
			breakpoints: {
				320: {
					slidesPerView: 1,
				},
				480: {
					slidesPerView: 1,
				},
				640: {
					slidesPerView: 5,
				},
			},
		});

		const productsNext = () => {
			productsSlider.value.$el.slideNext();
		};

		const productsPrev = () => {
			productsSlider.value.$el.slidePrev();
		};

		if (storeX.state.storeCode == "ar") {
			slideSubCatOpts.value.dir = "rtl";
			slideProductOpts.value.dir = "rtl";
		} else {
			slideSubCatOpts.value.dir = "ltr";
			slideProductOpts.value.dir = "ltr";
		}

		const category = ref({});
		const productsResults = ref([]);
		const selectedSubCategory = ref();
		const loadingProducts = ref(false);

		const getProducts = (categoryId) => {
			if (productsSlider.value) {
				productsSlider.value.$el.update();
			}
			loadingProducts.value = true;
			productsResults.value = [];
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("productsListByCategory"), {
					store_view: getLocalStorageData("storeCode"),
					category_id: categoryId,
					get_stock: true,
					sort_by: "random",
					sort_dir: "ASC",
					limit: 10,
					page: 1,
				})
				.then((response) => {
					loadingProducts.value = false;
					if (!Object.prototype.hasOwnProperty.call(response, "error")) {
						productsResults.value = response.items;
						if (productsSlider.value) {
							productsSlider.value.$el.update();
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
		};

		const selectSubCategory = (category) => {
			selectedSubCategory.value = category;
			getProducts(selectedSubCategory.value.entity_id);
		};

		const getCategoryChilds = () => {
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("categorySubById"), {
					store_view: getLocalStorageData("storeCode"),
					category_id: category.value["entity_id"],
				})
				.then((response) => {
					if (Object.prototype.hasOwnProperty.call(response, "items")) {
						category.value["childs"] = response.items;
						selectedSubCategory.value = response.items[0];
						getProducts(selectedSubCategory.value.entity_id);
					} else {
						category.value["childs"] = [];
						selectedSubCategory.value = category.value;
						getProducts(category.value["entity_id"]);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		};

		const have2Banners = ref(false);
		const getBanner2 = () => {
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("getBanner"), {
					banner_code: "home_category_" + category.value["entity_id"] + "_2",
				})
				.then(async (response: any) => {
					const resp = response;
					if (resp.success) {
						if (resp.data) {
							have2Banners.value = true;
							storeX.commit("addBanner", resp.data);
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
		};

		const getCategory = () => {
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("categoryById"), {
					store_view: getLocalStorageData("storeCode"),
					id: props.category_id,
				})
				.then((response) => {
					if (!Object.prototype.hasOwnProperty.call(response, "error")) {
						category.value = response.data;
						getCategoryChilds();
						getBanner2();
					}
				})
				.catch((error) => {
					console.log(error);
				});
		};

		const getCategoryImageUrl = (category: any) => {
			if (category.image) {
				return (
					getLocalStorageData("websiteUrl") +
					"media/catalog/category/" +
					category.image
				);
			} else {
				return "assets/images/placeholder.png";
			}
		};

		const getUrl = (path) => {
			const url = "/" + storeX.state.storeCode + "/" + path;
			return url;
		};

		const smallScreen = ref(false);
		const isSmallScreen = () => {
			const isSmall = window.innerWidth < 768;
			smallScreen.value = isSmall;
			return isSmall;
		};

		window.addEventListener("resize", () => {
			isSmallScreen();
		});

		const showSubsArrows = computed(() => {
			if (category.value) {
				if (category.value["childs"]) {
					const count = smallScreen.value ? 2 : 6;
					return category.value["childs"].length > count;
				}
				return false;
			}
			return false;
		});

		const showSubStartArrow = ref(false);
		const showSubEndArrow = ref(true);
		const ionSlideSubDidChange = (event) => {
			if (event.target.swiper.isBeginning) {
				showSubStartArrow.value = false;
			} else {
				showSubStartArrow.value = true;
			}
			if (event.target.swiper.isEnd) {
				showSubEndArrow.value = false;
			} else {
				showSubEndArrow.value = true;
			}
		};

		const showStartArrow = ref(false);
		const showEndArrow = ref(true);
		const ionSlideDidChange = (event) => {
			if (event.target.swiper.isBeginning) {
				showStartArrow.value = false;
			} else {
				showStartArrow.value = true;
			}
			if (event.target.swiper.isEnd) {
				showEndArrow.value = false;
			} else {
				showEndArrow.value = true;
			}
		};

		watch(
			() => storeX.state.storeCode,
			() => {
				getCategory();
				if (storeX.state.storeCode == "ar") {
					slideSubCatOpts.value.dir = "rtl";
					slideProductOpts.value.dir = "rtl";
				} else {
					slideSubCatOpts.value.dir = "ltr";
					slideProductOpts.value.dir = "ltr";
				}
			}
		);

		onMounted(() => {
			getCategory();
		});

		return {
			t,
			category,
			getCategoryImageUrl,
			getUrl,
			showSubsArrows,
			subCatSlider,
			slideSubCatOpts,
			subCatNext,
			subCatPrev,
			selectSubCategory,
			productsResults,
			loadingProducts,
			selectedSubCategory,
			productsSlider,
			productsNext,
			productsPrev,
			slideProductOpts,
			icons,
			have2Banners,
			ionSlideSubDidChange,
			showSubStartArrow,
			showSubEndArrow,
			ionSlideDidChange,
			showStartArrow,
			showEndArrow,
		};
	},
	components: {
		IonImg,
		IonSlides,
		IonSlide,
		IonIcon,
		IonSkeletonText,
		ProductItem,
		Banner,
	},
});
