
import { defineComponent } from "vue";
import { IonContent } from "@ionic/vue";
export default defineComponent({
  name: "LoadingModal",
  props: {
    message: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    return props;
  },
  components: {
    IonContent,
  },
});
