//import { createI18n, LocaleMessages, VueMessageType } from "vue-i18n";
import { createI18n } from "vue-i18n";
import en from "./locales/en.json";
import ar from "./locales/ar.json";

// type MessageSchema = typeof en;
// function loadLocaleMessages(): LocaleMessages<VueMessageType> {
// 	const locales = require.context(
// 		"./locales",
// 		true,
// 		/[A-Za-z0-9-_,\s]+\.json$/i
// 	);
// 	const messages: LocaleMessages<VueMessageType> = {};
// 	locales.keys().forEach((key) => {
// 		const matched = key.match(/([A-Za-z0-9-_]+)\./i);
// 		if (matched && matched.length > 1) {
// 			const locale = matched[1];
// 			messages[locale] = locales(key);
// 		}
// 	});
// 	return messages;
// }
const i18n = createI18n({
	legacy: false,
	globalInjection: true,
	locale: process.env.VUE_APP_I18N_LOCALE || "ar",
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "ar",
	messages: {
		en: en,
		ar: ar,
	},
	pluralRules: {
		en: function(choice, choicesLength) {
			if (choice === 0) {
				return 0;
			}
			const diff = choice - choicesLength;
			if (diff > 1 || diff == 0) {
				return choicesLength - 1;
			}
			return choice;
		},
		ar: function(choice, choicesLength) {
			if (choice === 0) {
				return 0;
			}
			const diff = choice - choicesLength;
			if (diff > 1 || diff == 0) {
				return choicesLength - 1;
			}
			return choice;
		},
	},
});
export default i18n;
