import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "main page-404"
}
const _hoisted_2 = { class: "page-content pt-50 pb-50" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-xl-8 col-lg-10 col-md-12 m-auto text-center" }
const _hoisted_6 = { class: "display-2 mb-30" }
const _hoisted_7 = { class: "font-lg text-grey-700 mb-30" }
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_MainFooter = _resolveComponent("MainFooter")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_MainHeader),
          (!_ctx.loading)
            ? (_openBlock(), _createElementBlock("main", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.t("pageNotFount")), 1),
                        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.t("pageNotFountMsg")), 1),
                        _createElementVNode("a", {
                          class: "btn btn-default submit-auto-width font-xs hover-up mt-30",
                          href: '/' + _ctx.storeX.state.storeCode,
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.nav('/' + _ctx.storeX.state.storeCode, $event)))
                        }, _toDisplayString(_ctx.t("backToHome")), 9, _hoisted_8)
                      ])
                    ])
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("footer", _hoisted_9, [
            _createVNode(_component_MainFooter)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}