import settingsApp from "./settings";
import httpApp from "./http";
import { store } from "@/store";
import { useGlobalFunctions } from "@/libs/globalFunctions";

import i18n from "@/i18n";
const { t, te } = i18n.global;

const { createLoading, openToast } = useGlobalFunctions();
const storeX = store;

const wishlistApp = {
	getWishlistItems: async (showLoading = false) => {
		const loading = await createLoading(t("pleaseWait"));
		if (showLoading) {
			loading.present();
		}
		return new Promise(function(resolve) {
			httpApp
				.sendGetRequest(settingsApp.getEndpointUrl("getWishlistItems"))
				.then((response: any) => {
					if (
						!Object.prototype.hasOwnProperty.call(response, "error")
					) {
						const items = response.items;
						const wishlistItems: any = {};
						let finishedProc = 0;
						for (let i = 0; i < items.length; i++) {
							const item = items[i];
							wishlistItems["prd_" + item.product_id] =
								item.wishlist_item_id;
							finishedProc++;
							if (finishedProc == items.length) {
								storeX.commit(
									"setWishlistItems",
									wishlistItems
								);
								resolve(response);
							}
						}
					} else {
						storeX.commit("setWishlistItems", []);
					}
					if (showLoading) {
						loading.dismiss();
					}
				})
				.catch(() => {
					if (showLoading) {
						loading.dismiss();
					}
				});
		});
	},

	addToWishlist: async (
		product: any,
		showLoading = false,
		silent = false
	) => {
		const loading = await createLoading(t("pleaseWait"));
		if (showLoading) {
			loading.present();
		}
		return new Promise(function(resolve, reject) {
			httpApp
				.sendPostRequest(
					settingsApp.getEndpointUrl("addWishlistItem"),
					{
						product: product.entity_id,
					}
				)
				.then((response: any) => {
					if (showLoading) {
						loading.dismiss();
					}
					if (
						!Object.prototype.hasOwnProperty.call(response, "error")
					) {
						resolve(response);
					} else {
						if (!silent) {
							if (te("apiMsgs." + response.error.code)) {
								openToast(
									t("apiMsgs." + response.error.code),
									"danger"
								);
							} else {
								openToast(response.error.message, "danger");
							}
						}
						reject(response);
					}
				})
				.catch((error) => {
					if (showLoading) {
						loading.dismiss();
					}
					reject(error);
				});
		});
	},

	removeFromWishlist: async (
		itemId: any,
		showLoading = false,
		silent = false
	) => {
		const loading = await createLoading(t("pleaseWait"));
		if (showLoading) {
			loading.present();
		}
		return new Promise(function(resolve, reject) {
			httpApp
				.sendPostRequest(
					settingsApp.getEndpointUrl("deleteWishlistItem"),
					{
						item: itemId,
					}
				)
				.then((response: any) => {
					if (showLoading) {
						loading.dismiss();
					}
					if (
						!Object.prototype.hasOwnProperty.call(response, "error")
					) {
						resolve(response);
					} else {
						if (!silent) {
							if (te("apiMsgs." + response.error.code)) {
								openToast(
									t("apiMsgs." + response.error.code),
									"danger"
								);
							} else {
								openToast(response.error.message, "danger");
							}
						}
						reject(response);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
};

export default wishlistApp;
