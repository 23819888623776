import { useLocalStorage } from "./localStorage";
import { Device } from "@capacitor/device";
import settingsApp from "./settings";
import httpApp from "./http";
import { store as storeX } from "@/store";
import {
	loadingController,
	modalController,
	toastController,
	ToastButton,
} from "@ionic/vue";
import LoadingModal from "@/components/LoadingModal.vue";
export function useGlobalFunctions() {
	const { getLocalStorageData } = useLocalStorage();

	const compareVersion = (a: any, b: any) => {
		if (a === b) {
			return 0;
		}

		if (a == null || b == null) {
			return 0;
		}

		const aComponents = a.split(".");
		const bComponents = b.split(".");

		const len = Math.min(aComponents.length, bComponents.length);

		// loop while the components are equal
		for (let i = 0; i < len; i++) {
			// A bigger than B
			if (parseInt(aComponents[i]) > parseInt(bComponents[i])) {
				return 1;
			}

			// B bigger than A
			if (parseInt(aComponents[i]) < parseInt(bComponents[i])) {
				return -1;
			}
		}

		// If one's a prefix of the other, the longer one is greater.
		if (aComponents.length > bComponents.length) {
			return 1;
		}

		if (aComponents.length < bComponents.length) {
			return -1;
		}

		// Otherwise they are the same.
		return 0;
	};

	const compareDate = (date1: any, date2: any) => {
		const d1 = new Date(date1);
		const d2 = new Date(date2);

		// Check if the dates are equal
		const same = d1.getTime() === d2.getTime();
		if (same) return 0;

		// Check if the first is greater than second
		if (d1 > d2) return 1;

		// Check if the first is less than second
		if (d1 < d2) return -1;
	};

	const setCookie = (cName: any, cValue: any, seconds: any) => {
		const d = new Date();

		if (seconds > 31536000) {
			seconds = 31536000;
		}
		d.setTime(d.getTime() + seconds * 1000);
		const exDate = "expires=" + d.toUTCString();
		document.cookie = cName + "=" + cValue + ";" + exDate + ";path=/";
	};

	const setCookieWithExpire = (cName: any, cValue: any, expires: any) => {
		const exDate = "expires=" + expires;
		document.cookie =
			cName + "=" + cValue + ";expires=" + exDate + ";path=/";
	};

	const getCookie = (cName: any) => {
		const name = cName + "=";
		const ca = document.cookie.split(";");
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == " ") {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return false;
	};

	const deleteCookie = (cName: any) => {
		document.cookie =
			cName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
	};

	const getNetworkIp = () => {
		return new Promise((resolve) => {
			resolve(null);
		});
	};

	const getAppVersion = async () => {
		const version = "1.9.0";
		return version;
	};

	const registerDevice = async (pushNotificationToken?: any) => {
		const devicecInfoPromise = Device.getInfo();
		const deviceIdPromise = Device.getId();
		const appVersion = await getAppVersion();
		let latestIp;
		try {
			latestIp = await getNetworkIp();
		} catch (error) {
			latestIp = null;
		}
		return new Promise((resolve, reject) => {
			const customerData = storeX.state.customerData;
			let customerId = 0;
			if (customerData) {
				customerId = Number(customerData.account_data.customer_id);
			}
			Promise.all([devicecInfoPromise, deviceIdPromise]).then(
				(responses) => {
					const devicecInfo = responses[0];
					const deviceId = responses[1];
					const uid = deviceId.uuid;

					const deviceData = {
						app_id: getLocalStorageData("appId"),
						app_version: appVersion,
						serial: uid,
						uuid: uid,
						last_ip: latestIp,
						storeview_code: getLocalStorageData("storeCode"),
						model: devicecInfo.model,
						manufacturer: devicecInfo.manufacturer,
						customer_id: customerId,
						firebase_token: pushNotificationToken,
					};

					httpApp
						.sendPostRequest(
							settingsApp.getEndpointUrl("deviceAdd"),
							{
								device: deviceData,
							}
						)
						.then((response: any) => {
							resolve(response);
						})
						.catch((error) => {
							console.log("deviceAdd", error);
							reject(error);
						});
				}
			);
		});
	};

	const createLoading = async (message: any, defaultLoading = false) => {
		if (defaultLoading) {
			const loading = await loadingController.create({
				message: message,
			});
			return loading;
		} else {
			const modal = await modalController.create({
				component: LoadingModal,
				backdropDismiss: false,
				swipeToClose: false,
				keyboardClose: false,
				cssClass: "loadingModal",
				componentProps: {
					message,
				},
			});
			return modal;
		}
	};

	const openToast = async (
		message: any,
		color: any,
		keyboardClose = true,
		duration = 10000,
		position: "top" | "bottom" | "middle" = "bottom",
		cssClass?: string,
		buttons?: (ToastButton | string)[]
	) => {
		if (!buttons) {
			buttons = [
				{
					text: storeX.state.storeCode == "en" ? "OK" : "قبول",
					role: "cancel",
				},
			];
		}
		const toast = await toastController.create({
			message: message,
			color: color,
			keyboardClose: keyboardClose,
			duration: duration,
			position: position,
			cssClass: cssClass,
			buttons: buttons,
		});
		return toast.present();
	};

	const formatNumberLocale = (input) => {
		return Number(input).toLocaleString();
	};

	return {
		compareVersion,
		compareDate,
		setCookie,
		setCookieWithExpire,
		getCookie,
		deleteCookie,
		getNetworkIp,
		getAppVersion,
		registerDevice,
		createLoading,
		openToast,
		formatNumberLocale,
	};
}
