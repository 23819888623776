import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "header-action-icon-2" }
const _hoisted_2 = ["alt"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = { class: "notifications-list" }
const _hoisted_5 = {
  key: 0,
  class: "notification-header"
}
const _hoisted_6 = ["textContent", "onClick"]
const _hoisted_7 = ["src"]
const _hoisted_8 = ["onClick", "innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_popover = _resolveComponent("ion-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.notificationsSetOpen(true, $event))),
      id: "notifications-trigger"
    }, [
      _createElementVNode("img", {
        class: "svgInject",
        alt: _ctx.t('header.notifications'),
        src: "assets/images/header/icon-bell.svg"
      }, null, 8, _hoisted_2),
      (_ctx.storeX.state.newNotificationsCount)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: "pro-count blue",
            textContent: _toDisplayString(_ctx.storeX.state.newNotificationsCount)
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_ion_popover, {
      class: "notifications-popover",
      side: "bottom",
      alignment: "start",
      mode: "ios",
      size: "auto",
      "is-open": _ctx.notificationsIsOpenRef,
      event: _ctx.notificationsEvent,
      onDidDismiss: _cache[2] || (_cache[2] = ($event: any) => (_ctx.notificationsSetOpen(false)))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_content, { class: "notifications-content" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification, notifIndx) => {
                return (_openBlock(), _createBlock(_component_ion_card, {
                  class: _normalizeClass(["notifications-item", { newNotification: notification.is_read == '0' }]),
                  key: notifIndx
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_content, { class: "notification-content" }, {
                      default: _withCtx(() => [
                        (notification.title || notification.icon)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                              _createElementVNode("strong", {
                                class: "notification-title",
                                textContent: _toDisplayString(notification.title),
                                onClick: ($event: any) => (_ctx.notificationClicked(notification))
                              }, null, 8, _hoisted_6),
                              (notification.icon)
                                ? (_openBlock(), _createBlock(_component_ion_thumbnail, {
                                    key: 0,
                                    class: "notification-icon",
                                    onClick: ($event: any) => (_ctx.notificationClicked(notification))
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("img", {
                                        src: notification.icon
                                      }, null, 8, _hoisted_7)
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_ion_icon, {
                          class: "notification-delete",
                          icon: _ctx.icons.close,
                          onClick: ($event: any) => (_ctx.deleteNotification(notification))
                        }, null, 8, ["icon", "onClick"]),
                        _createElementVNode("div", {
                          class: "notification-body",
                          onClick: ($event: any) => (_ctx.notificationClicked(notification)),
                          innerHTML: notification.text
                        }, null, 8, _hoisted_8)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["class"]))
              }), 128))
            ]),
            _createVNode(_component_ion_infinite_scroll, {
              disabled: _ctx.pages == _ctx.storeX.state.notificationsPage,
              onIonInfinite: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getNotifications($event))),
              threshold: "15px",
              id: "infinite-scroll"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_infinite_scroll_content, {
                  "loading-spinner": "bubbles",
                  loadingText: _ctx.t('pleaseWait')
                }, null, 8, ["loadingText"])
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["is-open", "event"])
  ]))
}