import { InjectionKey } from "vue";
import { createStore, useStore as baseUseStore, Store } from "vuex";

export interface State {
	storeCode: any;
	mainCategories: any;
	customerData: any;
	sellerData: any;
	currency: any;
	cartCount: number;
	cartItems: any;
	cartItemsValid: boolean;
	compareCount: number;
	compareItems: any;
	cartTotals: any;
	wishlistCount: number;
	wishlistItems: any;
	dark: boolean;
	menus: any;
	sliders: any;
	banners: any;
	home: any;
	pwaToken: any;
	pwaCode: any;
	shippingStateId: any;
	newNotificationsCount: number;
	notifications: Array<any>;
	notificationsPage: number;
	countryStates: Array<any>;
}

const domainLangUrl = window.location.origin;
const isAE = domainLangUrl.includes("ae.yallatager");
const countryCode = isAE ? "AE" : "EG";
const currencyCode = countryCode == "AE" ? "AED" : "EGP";

// define injection key
export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
	state: {
		storeCode: "ar",
		mainCategories: [],
		customerData: null,
		sellerData: null,
		currency: currencyCode,
		cartCount: 0,
		cartItems: {},
		cartTotals: {},
		cartItemsValid: true,
		compareCount: 0,
		compareItems: {},
		wishlistCount: 0,
		wishlistItems: {},
		dark: false,
		menus: [],
		sliders: [],
		banners: [],
		home: [],
		pwaToken: "",
		pwaCode: "",
		shippingStateId: 0,
		newNotificationsCount: 0,
		notifications: [],
		notificationsPage: 0,
		countryStates: [],
	},
	mutations: {
		setPwaToken(state: any, pwaToken: any) {
			state.pwaToken = pwaToken;
		},
		setShippingStateId(state: any, shippingStateId: any) {
			state.shippingStateId = shippingStateId;
		},
		setPwaCode(state: any, pwaCode: any) {
			state.pwaCode = pwaCode;
		},
		setStoreCode(state: any, storeCode: any) {
			state.storeCode = storeCode;
		},
		setMainCategories(state: any, mainCategories: any) {
			state.mainCategories = mainCategories;
		},
		setCustomerData(state: any, customerData: any) {
			state.customerData = customerData;
		},
		setSellerData(state: any, sellerData: any) {
			state.sellerData = sellerData;
		},
		setCurrency(state: any, currency: any) {
			state.currency = currency;
		},
		setCartCount(state: any, cartCount: any) {
			state.cartCount = cartCount;
		},
		setCartItems(state: any, items: any) {
			state.cartItems = items;
			if (!items) {
				state.cartCount = 0;
			} else {
				state.cartCount = Object.keys(items).length;
				state.cartItemsValid = true;
				Object.keys(items).forEach((key) => {
					const item = items[key];
					if (item.item.has_error) {
						state.cartItemsValid = false;
					}
				});
			}
		},
		setCartTotals(state: any, totals: any) {
			state.cartTotals = totals;
		},
		setCompareCount(state: any, compareCount: any) {
			state.compareCount = compareCount;
		},
		setCompareItems(state: any, items: any) {
			state.compareItems = items;
			// if (!items) {
			// 	state.compareCount = 0;
			// } else {
			// 	state.compareCount = Object.keys(items).length;
			// }
		},
		setWishlistItems(state: any, items: any) {
			state.wishlistItems = items;
			state.wishlistCount = Object.keys(items).length;
		},
		setDarkState(state: any, darkState: any) {
			state.dark = darkState;
		},
		addMenu(state: any, menu: any) {
			state.menus[menu.menu_data.identifier] = menu;
		},
		addSlider(state: any, slider: any) {
			state.sliders[slider.mobilePage] = [];
			state.sliders[slider.code] = slider;
		},
		removePageSliders(state: any, page: any) {
			state.sliders[page] = null;
		},
		addBanner(state: any, banner: any) {
			if (banner) {
				const page = banner.mobilePage ? banner.mobilePage : "generalPage";
				state.banners[page] = {};
				state.banners[page][banner.banner_code] = banner;
			}
		},
		removePageBanners(state: any, page: any) {
			state.banners[page] = null;
		},
		setHome(state: any, items: any) {
			state.home = items;
		},
		setNewNotificationsCount(state: any, count: number) {
			state.newNotificationsCount = count;
		},
		setNotifications(state: any, items: any) {
			state.notifications = items;
		},
		setNotificationsPage(state: any, page: number) {
			state.notificationsPage = page;
		},
		addNotification(state: any, item: any) {
			state.notifications.push(item);
		},
		removeNotification(state: any, itemId: any) {
			let currentNotification = state.notifications;
			currentNotification = currentNotification.filter((obj) => {
				return obj.entity_id !== itemId;
			});
			state.notifications = currentNotification;
		},
		setStates(state: any, states) {
			state.countryStates = states;
		},
	},
});

export function useStore() {
	return baseUseStore(key);
}
